import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

//Usable Icons:
import { faCircleNotch,faSpinner,faSignOutAlt,faFilePdf as fasPDF, faFileExcel, faEraser, 
  faMoneyBill,faCircle, faDollarSign, faListOl, faSync,faTimes, faChevronDown, faChevronUp, faUser, faCheckCircle, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
// import { faFilePdf as farPDF } from '@fortawesome/free-regular-svg-icons';

const WhiteListedIcons = {
  faCircleNotch, faSpinner, faSignOutAlt, fasPDF,faFileExcel, faEraser, 
  faMoneyBill, faCircle,faDollarSign, faListOl, faSync,faTimes, faChevronDown, faChevronUp,faUser,faCheckCircle, faTimesCircle
}


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  exports:[
    FontAwesomeModule
  ]
})
export class FaModule { 
  constructor() {
    // Add an icon to the library for convenient access in other components
    library.add(WhiteListedIcons);
  }
}
