import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_URL } from './api-url';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { UsuarioEntity } from '../classes/usuario-entity';
export class RequestClass {
    constructor(injector, http) {
        this.injector = injector;
        this.http = http;
        this.apiUrl = this.injector.get(API_URL);
    }
    _getApiHeaders() {
        let token = localStorage.getItem('token');
        let _sessionInfo = localStorage.getItem("sessionInfo");
        let sessionInfo = _sessionInfo != null ? JSON.parse(_sessionInfo) : new UsuarioEntity();
        if (!sessionInfo.ID)
            sessionInfo.ID = 0;
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            "idUser": sessionInfo.ID.toString(),
        });
        return headers;
    }
    _getApiHeaders_Public() {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Anonymous': 'true',
        });
        return headers;
    }
    ApiGet(url) {
        let headers = this._getApiHeaders();
        return this.http.get(this.prepareUrl(url), { headers: headers });
        // return this.http.get<T>(this.prepareUrl(url), { observe: 'response', headers: headers })
        //     .pipe(
        //         map((res) => {
        //             if (res.status == 401) {
        //                 if (res.headers.has("token_state") && res.headers.get("token_state") == "session_killed")
        //                     throw Error("SESSION_KILLED");
        //             }
        //             return res.body;
        //         }),
        //         catchError((err) => {
        //             
        //             // if(err.message == "SESSION_KILLED")
        //             //     this.handleSessionKilled();
        //             throw err;
        //         })
        //     );
    }
    ApiDelete(url) {
        let headers = this._getApiHeaders();
        let httpOptions = { headers };
        return this.http.delete(this.prepareUrl(url), httpOptions);
    }
    ApiPut(url, obj) {
        let headers = this._getApiHeaders();
        let httpOptions = { headers };
        return this.http.put(this.prepareUrl(url), obj, httpOptions);
    }
    ApiPatch(url, obj) {
        let headers = this._getApiHeaders();
        let httpOptions = { headers };
        return this.http.patch(this.prepareUrl(url), obj, httpOptions);
    }
    ApiGetFile(url) {
        let headers = this._getApiHeaders();
        return this.http.get(this.prepareUrl(url), { headers, responseType: 'blob', observe: 'response' }).pipe(map((res) => {
            return {
                file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
                filename: res.headers.get('filename')
            };
        }), catchError(e => {
            return of(null);
        }));
    }
    ApiGetP(url) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let headers = this._getApiHeaders();
            let httpOptions = { headers };
            return this.http.get(this.prepareUrl(url), httpOptions).toPromise();
        });
    }
    ApiGet_Public(url) {
        let headers = this._getApiHeaders_Public();
        let httpOptions = { headers };
        return this.http.get(this.prepareUrl(url), httpOptions);
    }
    ApiGet_PublicP(url) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let headers = yield this._getApiHeaders_Public();
            let httpOptions = { headers };
            return this.http.get(this.prepareUrl(url), httpOptions).toPromise();
        });
    }
    ApiPost(url, obj) {
        let headers = this._getApiHeaders();
        let httpOptions = { headers };
        return this.http.post(this.prepareUrl(url), obj, httpOptions);
    }
    ApiPostP(url, obj) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let headers = yield this._getApiHeaders();
            let httpOptions = { headers };
            return this.http.post(this.prepareUrl(url), obj, httpOptions).toPromise();
        });
    }
    ApiPost_Public(url, obj) {
        let headers = this._getApiHeaders_Public();
        let httpOptions = { headers };
        return this.http.post(this.prepareUrl(url), obj, httpOptions);
    }
    ApiPost_PublicP(url, obj) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let headers = yield this._getApiHeaders_Public();
            let httpOptions = { headers };
            return this.http.post(this.prepareUrl(url), obj, httpOptions).toPromise();
        });
    }
    isAbsoluteUrl(url) {
        const absolutePattern = /^https?:\/\//i;
        return absolutePattern.test(url);
    }
    prepareUrl(url) {
        url = this.isAbsoluteUrl(url) ? url : this.apiUrl + '/' + url;
        var urlrep = url.replace(/([^:]\/)\/+/g, '$1');
        return urlrep;
    }
}
