import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

  constructor(private auth: AuthService) { }

  ImgUrl = "assets/default_user.png"; // ../Content/img/Targaryen.png

  ngOnInit() {

  }

  DoLogout() {
    this.auth.doLogout().subscribe(x => {

    });
  }

  getNombre() {
    var sessioninfo = localStorage.getItem("sessionInfo");
    if (sessioninfo == null)
      return "";

    var info = JSON.parse(sessioninfo);
    return info.nombre + " " + info.apellido;
  }

}
