/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./item.component";
var styles_ItemComponent = [i0.styles];
var RenderType_ItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ItemComponent, data: {} });
export { RenderType_ItemComponent as RenderType_ItemComponent };
export function View_ItemComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.CurrencyPipe, [i1.LOCALE_ID]), i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(2, 0, null, null, 14, "div", [["class", "d-flex w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "icon "]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "concepto"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "estado"]], [[4, "color", null]], null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), (_l()(), i1.ɵeld(10, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "text-nowrap importe text-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), i1.ɵppd(13, 3), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "text-nowrap fecha  text-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, [" ", " "])), i1.ɵppd(16, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.icon, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.IconColor, ""); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.label; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.color; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.estado; _ck(_v, 9, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i1.ɵnov(_v, 0), _co.movi.IMPORTE, "$ ", "symbol")); _ck(_v, 12, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 15, 0, _ck(_v, 16, 0, i1.ɵnov(_v, 1), _co.movi.FECHA, "longDate")); _ck(_v, 15, 0, currVal_6); }); }
export function View_ItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-item", [], null, null, null, View_ItemComponent_0, RenderType_ItemComponent)), i1.ɵdid(1, 114688, null, 0, i3.ItemComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ItemComponentNgFactory = i1.ɵccf("app-item", i3.ItemComponent, View_ItemComponent_Host_0, { movi: "movi" }, {}, []);
export { ItemComponentNgFactory as ItemComponentNgFactory };
