export class UsuarioEntity {
  public ID: number;
  public username: string;
  public email: string;
  public cvu: object;
  public apellido: string;
  public comercio: number;
  public cuit: number;
  public dni: number;
  public first_login: boolean;
  public idPadre: number;
  public id_banka: number;
  public nombre: string;
  public nombrePadre: string;
  public sexo: string
  public tarjeta: number;
  public tipo: number;
  public pjEstadoRegistro: number;
  public parentData: any;
  public RAZON_SOCIAL: string;
  public CUIT: number;
  public NOMBRE_FANTASIA: string;
  public estado: number;
}