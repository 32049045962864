<mat-toolbar color="primary" class="mat-elevation-z6">

  <mat-toolbar-row>
    <button class="hambutton" type="button" aria-label="Toggle sidenav" (click)="drawer.toggle()"
      *ngIf="isHandset$ | async">
      <mat-icon>menu</mat-icon>
    </button>
    <ng-content select="[navbar]" class="AppNav "></ng-content>
  </mat-toolbar-row>
</mat-toolbar>
<mat-drawer-container [class.thinnerNav]="(thinnerNav$ | async)">
  <mat-drawer #drawer [attr.role]=" (isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]=" (isHandset$ | async) ? 'over' : 'side'" [opened]=" !(isHandset$ | async)" opened>
    <perfect-scrollbar class="scrollarea" [class.thinnerNav]="(thinnerNav$ | async)">
      <ng-content select="[drawer]"></ng-content>
    </perfect-scrollbar>
  </mat-drawer>
  <mat-drawer-content>
    <perfect-scrollbar class="scrollarea scrollareaRouterOutlet" [class.thinnerNav]="(thinnerNav$ | async)">
      <ng-content select="[body]"></ng-content>
    </perfect-scrollbar>
  </mat-drawer-content>
</mat-drawer-container>