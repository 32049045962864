import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { ToastrService } from 'ngx-toastr';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
import * as i2 from "@angular/cdk/layout";
export class NotifService {
    constructor(toast, breakpointObserver) {
        this.toast = toast;
        this.breakpointObserver = breakpointObserver;
        this.positionClass = 'toast-top-right';
        this.breakpointObserver
            .observe([Breakpoints.HandsetLandscape, Breakpoints.Handset])
            .subscribe((state) => {
            this.positionClass = 'toast-top-right';
            if (state.matches) {
                this.positionClass = 'toast-bottom-center';
            }
        });
    }
    success(msg, title, duration = 2000, showClose = true, showLoading = true, positionClass = this.positionClass) {
        this.toast.success(msg, title, {
            timeOut: duration,
            closeButton: showClose,
            progressBar: showLoading,
            progressAnimation: 'increasing',
            positionClass: positionClass
        });
    }
    error(msg, title, duration = 2000, showClose = true, showLoading = true, positionClass = this.positionClass) {
        this.toast.error(msg, title, {
            timeOut: duration,
            closeButton: showClose,
            progressBar: showLoading,
            progressAnimation: 'increasing',
            positionClass: positionClass
        });
    }
    warning(msg, title, duration = 2000, showClose = true, showLoading = true, positionClass = this.positionClass) {
        this.toast.warning(msg, title, {
            timeOut: duration,
            closeButton: showClose,
            progressBar: showLoading,
            progressAnimation: 'increasing',
            positionClass: positionClass
        });
    }
    info(msg, title, duration = 2000, showClose = true, showLoading = true, positionClass = this.positionClass) {
        this.toast.info(msg, title, {
            timeOut: duration,
            closeButton: showClose,
            progressBar: showLoading,
            progressAnimation: 'increasing',
            positionClass: positionClass
        });
    }
}
NotifService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotifService_Factory() { return new NotifService(i0.ɵɵinject(i1.ToastrService), i0.ɵɵinject(i2.BreakpointObserver)); }, token: NotifService, providedIn: "root" });
