/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./e404.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "./e404.component";
import * as i7 from "@angular/router";
var styles_E404Component = [i0.styles];
var RenderType_E404Component = i1.ɵcrt({ encapsulation: 0, styles: styles_E404Component, data: {} });
export { RenderType_E404Component as RenderType_E404Component };
export function View_E404Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "walletContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "Content404"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "imgcontainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["404"])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "btncontainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No pudimos encontrar la pagina que buscas."])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["color", "primary"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotohome() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Ir al inicio"]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 9).disabled || null); var currVal_1 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_0, currVal_1); }); }
export function View_E404Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-e404", [], null, null, null, View_E404Component_0, RenderType_E404Component)), i1.ɵdid(1, 114688, null, 0, i6.E404Component, [i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var E404ComponentNgFactory = i1.ɵccf("app-e404", i6.E404Component, View_E404Component_Host_0, {}, {}, []);
export { E404ComponentNgFactory as E404ComponentNgFactory };
