import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS, NativeDateAdapter, DateAdapter } from '@angular/material';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { API_URL } from './services/api-url';
// import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { RoutesModule } from './pages/routes.module';
import { CommonModule } from '@angular/common';

import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth.service';
import { RequestClass } from './services/request.class';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { HomeService } from './services/home.service';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es-AR';
import { CuentaEmpresaComponent } from './public/register/cuenta-empresa/cuenta-empresa.component';
import { CuentaPersonaComponent } from './public/register/cuenta-persona/cuenta-persona.component';
import { VerificacionIdentidadRepresentanteComponent } from './public/register/cuenta-empresa/verificacion-identidad-representante/verificacion-identidad-representante.component';
import { RegretBajaComponent } from './public/regret-baja/regret-baja.component';

registerLocaleData(localeEs);




const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};


@Injectable({ providedIn: 'root' })
export class AppDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
    if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
      const str = value.split('/');
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      return new Date(year, month, date);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
  format(date: Date, displayFormat: any): string {
    if (displayFormat == "input") {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      return this._to2digit(day) + '/' + this._to2digit(month) + '/' + year;
    } else {
      return date.toDateString();
    }
  }

  private _to2digit(n: number) {
    return ('00' + n).slice(-2);
  }
}

const MY_DATE_FORMATS = {
  // parse: {
  //    dateInput: {month: 'string', year: 'numeric', day: 'numeric'}
  // },
  // display: {
  //    dateInput: 'input',
  //    monthYearLabel: {year: 'numeric', month: 'short'},
  //    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
  //    monthYearA11yLabel: {year: 'numeric', month: 'long'},
  // }
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
  },
  display: {
    // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    dateInput: 'input',
    monthYearLabel: { month: 'short', year: 'numeric', day: 'numeric' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }

};


@NgModule({
  declarations: [
    AppComponent,
    CuentaEmpresaComponent,
    CuentaPersonaComponent,
    VerificacionIdentidadRepresentanteComponent,
    RegretBajaComponent




  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    // PerfectScrollbarModule,
    RoutesModule,
    MatCarouselModule,

    MatCardModule,
    ToastrModule.forRoot({
      timeOut: 2000,

      preventDuplicates: true,
    }),
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    // { provide: HTTP_INTERCEPTORS, useClass: TerminateSessionInterceptor, multi: true },
    { provide: API_URL, useValue: environment.apiUrl },
    { provide: MAT_DATE_LOCALE, useValue: 'es-AR' },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: LOCALE_ID, useValue: 'es-Ar' },
    // { provide: LOCALE_ID, useValue: 'es-AR' },
    AuthService,
    RequestClass,
    HomeService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
