import { RequestClass } from './request.class';
import { BehaviorSubject, timer, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "./request.class";
import * as i2 from "./auth.service";
export class HomeService {
    constructor(request, auth) {
        this.request = request;
        this.auth = auth;
        this.$saldo = new BehaviorSubject(0);
        this.$pendienteEntrada = new BehaviorSubject(0);
        this.$pendienteSalida = new BehaviorSubject(0);
        this.saldoSub();
        this.authstatussub = this.auth.loginStatus.pipe(switchMap((x) => {
            if (x)
                return this.req();
            else {
                return of(null);
            }
        })).subscribe(x => {
            if (x != null) {
                console.log("saldo updatedOnLogin");
                this.$saldo.next(x.data.saldo);
                this.$pendienteEntrada.next(x.data.saldo_a_disponer);
                this.$pendienteSalida.next(x.data.saldo_a_salir);
                // this.saldoSub();
            }
            else {
                console.log("saldo cleared on logout");
                this.$saldo.next(0);
                this.$pendienteEntrada.next(0);
                this.$pendienteSalida.next(0);
                // this.homeInfosub.unsubscribe();
            }
        });
    }
    GetMovimientosTop(onlyTop = 1) {
        return this.request.ApiGet(`/movimientos/top/4/0/0/0/` + onlyTop).pipe(catchError(e => {
            return of({ res: false, err: "Se Produjo un error inesperado." });
        }));
    }
    saldoSub() {
        this.homeInfosub = timer(0, 5000).pipe(
        // switchMap(() =>
        //   this.auth.IsLoggedIn()
        // ),
        switchMap((x) => {
            if (this.auth.loginStatus.value) {
                return this.req();
            }
            else
                return of(null);
        })).subscribe(x => {
            if (x != null) {
                console.log("saldo updated");
                this.$saldo.next(x.data.saldo);
                this.$pendienteEntrada.next(x.data.saldo_a_disponer);
                this.$pendienteSalida.next(x.data.saldo_a_salir);
            }
        });
    }
    req() {
        return this.request.ApiGet("/home/info");
    }
    dispose() {
        if (this.homeInfosub)
            this.homeInfosub.unsubscribe();
    }
}
HomeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HomeService_Factory() { return new HomeService(i0.ɵɵinject(i1.RequestClass), i0.ɵɵinject(i2.AuthService)); }, token: HomeService, providedIn: "root" });
function using(resource, func) {
    try {
        func(resource);
    }
    finally {
        resource.dispose();
    }
}
