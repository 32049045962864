import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { E404Component } from '../public/e404/e404.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from '../public/login/login.component';
import { AuthGuard } from '../services/auth.guard';
import { SharedModule } from '../shared/shared.module';
import { RegisterComponent } from '../public/register/register.component';
import { ValidateRecoverPasswordComponent } from '../public/validate-recover-password/validate-recover-password.component';
import { MainLayoutComponent } from '../shared/components/main-layout/main-layout.component';
import { ActivateAccountComponent } from '../public/activate-account/activate-account.component';
import { RecoverPasswordComponent } from '../public/recover-password/recover-password.component';
import { MovimientosComponent } from './movimientos/movimientos.component';
import { HomeModule } from './home/home.module';
import { IngresarComponent } from './ingresar/ingresar.component';
import { DetalleComponent } from './movimientos/components/detalle/detalle.component';
import { DetalleEnvioDineroComponent } from './movimientos/components/detalle/detalle-envio-dinero/detalle-envio-dinero.component';

import { DetalleRetiroSucursalComponent } from './movimientos/components/detalle/detalle-retiro-sucursal/detalle-retiro-sucursal.component';
import { DetalleCargaSaldoComponent } from './movimientos/components/detalle/detalle-carga-saldo/detalle-carga-saldo.component';
import { DetalleCobroQRComponent } from './movimientos/components/detalle/detalle-cobro-qr/detalle-cobro-qr.component';
import { RecargasComponent } from './recargas/recargas.component';
import { DetalleCobroLinkComponent } from './movimientos/components/detalle/detalle-cobro-link/detalle-cobro-link.component';
import { DetalleRecargaSubeComponent } from './movimientos/components/detalle/detalle-recarga-sube/detalle-recarga-sube.component';
import { DetalleIngresoPrestamoComponent } from './movimientos/components/detalle/detalle-ingreso-prestamo/detalle-ingreso-prestamo.component';
import { CuentaPersonaComponent } from '../public/register/cuenta-persona/cuenta-persona.component';
import { CuentaEmpresaComponent } from '../public/register/cuenta-empresa/cuenta-empresa.component';
import { PjGuard } from '../services/pj.guard';
import { VerificacionIdentidadRepresentanteComponent } from '../public/register/cuenta-empresa/verificacion-identidad-representante/verificacion-identidad-representante.component';
import { ColaboradorGuard } from '../services/colaborador.guard';
import { RegretBajaComponent } from '../public/regret-baja/regret-baja.component';



//Routes Declarations:
const routes: Routes = [
  // { path: '', redirectTo: '/inicio', pathMatch: 'full' },
  {
    path: '',
    canActivate: [AuthGuard, PjGuard], component: MainLayoutComponent,
    children: [
      { path: '', redirectTo: "inicio", pathMatch: "full" },
      { path: 'app/lc/:codigo', redirectTo: "cobrar/link/:codigo", pathMatch: "full" },
      { path: 'inicio', component: HomeComponent, data: { title: 'Inicio' } },

      { path: 'movimientos', component: MovimientosComponent, data: { title: 'Movimientos' } },
      { path: 'movimientos/:id', component: DetalleComponent, data: { title: 'Detalle' } },
      {
        path: 'cobrar', loadChildren: () => import('./cobrar/cobrar.module').then(m => m.CobrarModule)
      },
      {
        canActivate: [ColaboradorGuard], path: 'recargas', loadChildren: () => import('./recargas/recargas.module').then(m => m.RecargasModule)
      },
      {
        canActivate: [ColaboradorGuard], path: 'retirar', loadChildren: () => import('./retirar/retirar.module').then(m => m.RetirarModule)
      },
      {
        canActivate: [ColaboradorGuard], path: 'ingresar', loadChildren: () => import('./ingresar/ingresar.module').then(m => m.IngresarModule)
      },
      { canActivate: [ColaboradorGuard], path: 'configuracion', loadChildren: () => import('./configuracion/configuracion.module').then(m => m.ConfiguracionModule) },

    ]
  },

  //nonLayout
  { path: 'validacion_identidad', component: VerificacionIdentidadRepresentanteComponent, data: { title: '' } },
  { path: 'login', component: LoginComponent },
  // { path: 'register', component: RegisterComponent },
  // { path: 'register/persona', component: CuentaPersonaComponent },
  // { path: 'register/empresa', component: CuentaEmpresaComponent },
  { path: 'recover_password', component: ValidateRecoverPasswordComponent },
  { path: 'recover_password/:k', component: RecoverPasswordComponent },
  { path: 'activate_account/:k', component: ActivateAccountComponent },
  { path: 'revertir_baja/:k', component: RegretBajaComponent },
  { path: '**', component: E404Component }
];


@NgModule({
  declarations: [
    E404Component,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    ValidateRecoverPasswordComponent,
    RecoverPasswordComponent,
    MainLayoutComponent,
    ActivateAccountComponent,
    MovimientosComponent,
    IngresarComponent,
    DetalleComponent,
    DetalleEnvioDineroComponent,
    DetalleRetiroSucursalComponent,
    DetalleCargaSaldoComponent,
    DetalleCobroQRComponent,
    RecargasComponent,
    IngresarComponent,
    DetalleCobroLinkComponent,
    DetalleRecargaSubeComponent,
    DetalleIngresoPrestamoComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    HomeModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class RoutesModule { }
