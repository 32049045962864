<app-layout class="layout">
    <div navbar class="w-100 d-flex">
        <app-navbarcontent [Title]="Title" class="d-flex w-100 align-items-center">
            <div>
                <app-bar-saldo *ngIf="Title != 'Inicio'"></app-bar-saldo>
            </div>
            <div>
                <app-user-menu></app-user-menu>
            </div>

        </app-navbarcontent>
    </div>
    <div body style="text-align: center;">
        <img src="assets/slider01.png" alt="" height="600" width="600">
        <h5>Bajate nuestra app.. y segui los pasos para verificar la identidad del representante</h5>

        <a href="https://play.google.com/"><img src="assets/google-play.svg" alt="" height="50"></a>
    </div>

</app-layout>