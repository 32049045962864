import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/router";
export class PjGuard {
    constructor(auth, router) {
        this.auth = auth;
        this.router = router;
    }
    canActivate(next, state) {
        var userInfo = this.auth.getSessionInfo();
        if (userInfo) {
            if (userInfo.sexo == "J" && userInfo.pjEstadoRegistro != 2) {
                this.router.navigate(['/validacion_identidad']);
                return false;
            }
        }
        return true;
    }
}
PjGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PjGuard_Factory() { return new PjGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router)); }, token: PjGuard, providedIn: "root" });
