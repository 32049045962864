/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./slide-show.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ngmodule/material-carousel/ngmodule-material-carousel.ngfactory";
import * as i3 from "@ngmodule/material-carousel";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/animations";
import * as i6 from "@angular/common";
import * as i7 from "./slide-show.component";
var styles_SlideShowComponent = [i0.styles];
var RenderType_SlideShowComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SlideShowComponent, data: {} });
export { RenderType_SlideShowComponent as RenderType_SlideShowComponent };
function View_SlideShowComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-carousel-slide", [], null, null, null, i2.View_MatCarouselSlideComponent_0, i2.RenderType_MatCarouselSlideComponent)), i1.ɵdid(1, 114688, [[1, 4], ["matCarouselSlide", 4]], 0, i3.MatCarouselSlideComponent, [i4.DomSanitizer], { image: [0, "image"], hideOverlay: [1, "hideOverlay"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.image; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_SlideShowComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "carrouselContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "mat-carousel", [["color", "primary"], ["interval", "3000"], ["maintainAspectRatio", "false"], ["maxWidth", "auto"], ["orientation", "ltr"], ["slideHeight", "100%"], ["slides", "3"], ["timings", "250ms ease-in"]], null, [[null, "keyup"], [null, "mouseenter"], [null, "mouseleave"], [null, "mousewheel"], ["window", "resize"]], function (_v, en, $event) { var ad = true; if (("keyup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onKeyUp($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).onMouseLeave() !== false);
        ad = (pd_2 && ad);
    } if (("mousewheel" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2).onMouseWheel($event) !== false);
        ad = (pd_3 && ad);
    } if (("window:resize" === en)) {
        var pd_4 = (i1.ɵnov(_v, 2).onResize($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i2.View_MatCarouselComponent_0, i2.RenderType_MatCarouselComponent)), i1.ɵdid(2, 5423104, null, 1, i3.MatCarouselComponent, [i5.AnimationBuilder, i1.Renderer2, i1.PLATFORM_ID], { timings: [0, "timings"], autoplay: [1, "autoplay"], interval: [2, "interval"], loop: [3, "loop"], hideArrows: [4, "hideArrows"], hideIndicators: [5, "hideIndicators"], color: [6, "color"], maxWidth: [7, "maxWidth"], slides: [8, "slides"], useKeyboard: [9, "useKeyboard"], useMouseWheel: [10, "useMouseWheel"], orientation: [11, "orientation"] }, null), i1.ɵqud(603979776, 1, { slidesList: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SlideShowComponent_1)), i1.ɵdid(5, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "250ms ease-in"; var currVal_1 = true; var currVal_2 = "3000"; var currVal_3 = true; var currVal_4 = false; var currVal_5 = false; var currVal_6 = "primary"; var currVal_7 = "auto"; var currVal_8 = "3"; var currVal_9 = true; var currVal_10 = false; var currVal_11 = "ltr"; _ck(_v, 2, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); var currVal_12 = _co.imageObject; _ck(_v, 5, 0, currVal_12); }, null); }
export function View_SlideShowComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-slide-show", [], null, null, null, View_SlideShowComponent_0, RenderType_SlideShowComponent)), i1.ɵdid(1, 114688, null, 0, i7.SlideShowComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SlideShowComponentNgFactory = i1.ɵccf("app-slide-show", i7.SlideShowComponent, View_SlideShowComponent_Host_0, {}, {}, []);
export { SlideShowComponentNgFactory as SlideShowComponentNgFactory };
