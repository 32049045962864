import { Component, OnInit } from '@angular/core';
import { TituloService } from 'src/app/services/titulo.service';

@Component({
  selector: 'app-verificacion-identidad-representante',
  templateUrl: './verificacion-identidad-representante.component.html',
  styleUrls: ['./verificacion-identidad-representante.component.scss']
})
export class VerificacionIdentidadRepresentanteComponent implements OnInit {


  constructor(private title: TituloService) { }
  private _titulo = `Fértil Suma`;
  Title: string = ``;


  ngOnInit() {
    this.title.getTitulo().subscribe(x => x != "" ? this.Title = `${x}` : "");


  }

}
