import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class ColaboradorGuard {
    constructor(router) {
        this.router = router;
    }
    canActivate(next, state) {
        debugger;
        if (JSON.parse(localStorage.getItem("sessionInfo")).tipo != 1) {
            this.router.navigate(['/inicio']);
            return false;
        }
        return true;
    }
}
ColaboradorGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ColaboradorGuard_Factory() { return new ColaboradorGuard(i0.ɵɵinject(i1.Router)); }, token: ColaboradorGuard, providedIn: "root" });
