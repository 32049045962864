import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NotifService } from 'src/app/services/notif.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


enum ActivateScreen {
  loading, success, bad_key_error, key_expired_error, unknown_error
}



@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent implements OnInit {



  k: string = "";
  screen: ActivateScreen = ActivateScreen.loading;

  constructor(
    private router: Router, private ar: ActivatedRoute,
    private auth: AuthService, private notif: NotifService, private breakpointObserver: BreakpointObserver) { }


  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  navigateToLogin() {
    this.router.navigateByUrl('/login');
  }


  ngOnInit() {
    this.ar.params.subscribe(x => {
      this.k = x["k"];
      this.auth.ActivateAccount(this.k).subscribe(x => {

        if (!x.res) {
          switch (x.err) {
            case "bad_key":
              this.screen = ActivateScreen.bad_key_error;
              break;
            case "key_expired":
              this.screen = ActivateScreen.key_expired_error;
              break;
            default:
              this.screen = ActivateScreen.unknown_error;
              break;
          }
        }
        else {
          this.screen = ActivateScreen.success;
        }
      });
    })
  }






}



