import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


//Componentes de paginas
import { SaldoComponent } from './components/saldo/saldo.component';
import { TopMovimientosComponent } from './components/top-movimientos/top-movimientos.component';
import { OpGridComponent } from './components/op-grid/op-grid.component';
import { MaterialModule } from 'src/app/shared/material.module';
import { SaldoEntradaComponent } from './components/saldo-entrada/saldo-entrada.component';
import { SaldoSalidaComponent } from './components/saldo-salida/saldo-salida.component';
import { InversionComponent } from './components/inversion/inversion.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { BannerPublicidadComponent } from './components/bannerPublicidad/bannerPublicidad.component';


const ExportComponents = [
  SaldoComponent, SaldoEntradaComponent, SaldoSalidaComponent, TopMovimientosComponent,
  OpGridComponent, BannerPublicidadComponent, InversionComponent
]


@NgModule({
  declarations: [
    ...ExportComponents,
    SaldoEntradaComponent,
    SaldoSalidaComponent,
    InversionComponent,
    BannerPublicidadComponent,

  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule
  ],
  exports: [
    ...ExportComponents
  ]
})
export class HomeModule { }
