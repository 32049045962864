<!-- <mat-action-list>
  <mat-list-item>
    <mat-icon matListIcon>home</mat-icon>
    <mat-label>Movimientos</mat-label>
  </mat-list-item>

  <mat-list-item>
    <mat-icon matListIcon>person</mat-icon>
    <mat-label>Mi cuenta</mat-label>

  </mat-list-item>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>

        <mat-panel-description>
          Type your name and age
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-list-item>
        <mat-icon matListIcon>person</mat-icon>
        <mat-label>Mi cuenta</mat-label>

      </mat-list-item>
      <mat-list-item>
        <mat-icon matListIcon>person</mat-icon>
        <mat-label>Mi cuenta</mat-label>

      </mat-list-item>
      <mat-list-item>
        <mat-icon matListIcon>person</mat-icon>
        <mat-label>Mi cuenta</mat-label>

      </mat-list-item>
      <mat-list-item>
        <mat-icon matListIcon>person</mat-icon>
        <mat-label>Mi cuenta</mat-label>

      </mat-list-item>
      <mat-list-item>
        <mat-icon matListIcon>person</mat-icon>
        <mat-label>Mi cuenta</mat-label>

      </mat-list-item>
      <mat-list-item>
        <mat-icon matListIcon>person</mat-icon>
        <mat-label>Mi cuenta</mat-label>

      </mat-list-item>

    </mat-expansion-panel>
  </mat-accordion>
  <mat-list-item>
    <mat-icon matListIcon>settings</mat-icon>
    <mat-label>Configuración</mat-label>

  </mat-list-item>
</mat-action-list> -->


<div class="menuContainer">
  <ng-container *ngFor="let item of items; let i = index">
    <app-menu-item [item]=item [isLast]="(i+1 == items.length)"></app-menu-item>
  </ng-container>






  <!-- <div class="itemContainer" *ngFor="let item of items;">
    <div (click)="toggle(item)" *ngIf="!item.expand" class="itemContainer">
      <div class="icono">
        <mat-icon matListIcon>{{item.icono}}</mat-icon>

      </div>
      <div class="item">
        <label for="">{{item.titulo}}</label>

      </div>
      <div class="arrow" *ngIf=item.children.length>
        <mat-icon matListIcon>keyboard_arrow_down</mat-icon>
      </div>



    </div>

  <div *ngIf="item.expand">
    <div class="childrenContainer" *ngFor="let children of item.children;">
      <div class="icono">
        <mat-icon matListIcon>{{children.icono}}</mat-icon>

      </div>
      <div class="item">
        <label for="">{{children.titulo}}asdasdasd</label>

      </div>
    </div>
  </div>

</div>
-->

</div>