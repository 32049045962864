import { NativeDateAdapter } from '@angular/material';
import { environment } from 'src/environments/environment';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es-AR';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/core";
import * as i2 from "@angular/cdk/platform";
registerLocaleData(localeEs);
const DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true
};
export class AppDateAdapter extends NativeDateAdapter {
    parse(value) {
        if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
            const str = value.split('/');
            const year = Number(str[2]);
            const month = Number(str[1]) - 1;
            const date = Number(str[0]);
            return new Date(year, month, date);
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    }
    format(date, displayFormat) {
        if (displayFormat == "input") {
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            return this._to2digit(day) + '/' + this._to2digit(month) + '/' + year;
        }
        else {
            return date.toDateString();
        }
    }
    _to2digit(n) {
        return ('00' + n).slice(-2);
    }
}
AppDateAdapter.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppDateAdapter_Factory() { return new AppDateAdapter(i0.ɵɵinject(i1.MAT_DATE_LOCALE, 8), i0.ɵɵinject(i2.Platform)); }, token: AppDateAdapter, providedIn: "root" });
const MY_DATE_FORMATS = {
    // parse: {
    //    dateInput: {month: 'string', year: 'numeric', day: 'numeric'}
    // },
    // display: {
    //    dateInput: 'input',
    //    monthYearLabel: {year: 'numeric', month: 'short'},
    //    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    //    monthYearA11yLabel: {year: 'numeric', month: 'long'},
    // }
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
    },
    display: {
        // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
        dateInput: 'input',
        monthYearLabel: { month: 'short', year: 'numeric', day: 'numeric' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    }
};
const ɵ0 = DEFAULT_PERFECT_SCROLLBAR_CONFIG, ɵ1 = environment.apiUrl, ɵ2 = MY_DATE_FORMATS;
export class AppModule {
}
export { ɵ0, ɵ1, ɵ2 };
