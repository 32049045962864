<div class="walletContainer">
  <div class="Content404">
    <div class="imgcontainer">
      <h1>404</h1>
    </div>
    <div class="btncontainer">
      <h3>No pudimos encontrar la pagina que buscas.</h3>
      <button mat-raised-button color="primary" (click)="gotohome()">Ir al inicio</button>
    </div>
  </div>
</div>