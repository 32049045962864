/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navbarcontent.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./navbarcontent.component";
var styles_NavbarcontentComponent = [i0.styles];
var RenderType_NavbarcontentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavbarcontentComponent, data: {} });
export { RenderType_NavbarcontentComponent as RenderType_NavbarcontentComponent };
export function View_NavbarcontentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "F\u00E9rtil Suma"], ["height", "50"], ["src", "assets/LogoWallet_blanco.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "ml-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "mx-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "ml-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "d-flex"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.Title; _ck(_v, 2, 0, currVal_0); }); }
export function View_NavbarcontentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbarcontent", [], null, null, null, View_NavbarcontentComponent_0, RenderType_NavbarcontentComponent)), i1.ɵdid(1, 114688, null, 0, i2.NavbarcontentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavbarcontentComponentNgFactory = i1.ɵccf("app-navbarcontent", i2.NavbarcontentComponent, View_NavbarcontentComponent_Host_0, { Title: "Title" }, {}, ["*"]);
export { NavbarcontentComponentNgFactory as NavbarcontentComponentNgFactory };
