/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./components/saldo/saldo.component.ngfactory";
import * as i3 from "./components/saldo/saldo.component";
import * as i4 from "../../services/home.service";
import * as i5 from "./components/saldo-entrada/saldo-entrada.component.ngfactory";
import * as i6 from "./components/saldo-entrada/saldo-entrada.component";
import * as i7 from "@angular/router";
import * as i8 from "./components/saldo-salida/saldo-salida.component.ngfactory";
import * as i9 from "./components/saldo-salida/saldo-salida.component";
import * as i10 from "./components/top-movimientos/top-movimientos.component.ngfactory";
import * as i11 from "./components/top-movimientos/top-movimientos.component";
import * as i12 from "./home.component";
import * as i13 from "../../services/titulo.service";
import * as i14 from "../../services/auth.service";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "saldo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-saldo", [], null, null, null, i2.View_SaldoComponent_0, i2.RenderType_SaldoComponent)), i1.ɵdid(3, 245760, null, 0, i3.SaldoComponent, [i4.HomeService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "entrada"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-saldo-entrada", [["class", "saldosAbajo"]], null, null, null, i5.View_SaldoEntradaComponent_0, i5.RenderType_SaldoEntradaComponent)), i1.ɵdid(6, 245760, null, 0, i6.SaldoEntradaComponent, [i4.HomeService, i7.Router], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "salida"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-saldo-salida", [["class", "saldosAbajo"]], null, null, null, i8.View_SaldoSalidaComponent_0, i8.RenderType_SaldoSalidaComponent)), i1.ɵdid(9, 245760, null, 0, i9.SaldoSalidaComponent, [i4.HomeService], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "movimientos"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-top-movimientos", [], null, null, null, i10.View_TopMovimientosComponent_0, i10.RenderType_TopMovimientosComponent)), i1.ɵdid(12, 114688, null, 0, i11.TopMovimientosComponent, [i4.HomeService], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/ubuntu1.jpg"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 6, 0); _ck(_v, 9, 0); _ck(_v, 12, 0); }, null); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 114688, null, 0, i12.HomeComponent, [i13.TituloService, i14.AuthService, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("app-home", i12.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
