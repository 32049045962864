<div id="publicContainer">
	<div class="slideshowContainer">
		<app-slide-show></app-slide-show>
	</div>
	<div class="formContainer">
		<mat-card>
			<form novalidate (ngSubmit)="onSubmit()" [formGroup]="formulario">
				<mat-card-header>
					<mat-card-title-group>
						<mat-card-title><img mat-card-image src="/assets/LogoWallet.png"></mat-card-title>
						<mat-card-subtitle> </mat-card-subtitle>
					</mat-card-title-group>
				</mat-card-header>
				<mat-card-content>

				</mat-card-content>
				<div class="g-play" (click)="gotoPlayStore()">
					<span color="primary">Descarga la App en</span>
					<img src="assets/gplay.png" alt="">



				</div>
				<!-- <mat-card-content>
					<div>
						<mat-form-field appearance="outline">
							<mat-label>
								<mat-icon matSuffix>email</mat-icon> Email
							</mat-label>
							<input type="email" matInput formControlName="login" required>
						</mat-form-field>
						<mat-error
							*ngIf="submitted && formulario.controls.login.errors?.email && !formulario.controls.login.errors?.required">
							<small>Email inválido</small> </mat-error>
						<mat-error *ngIf="submitted && formulario.controls.login.errors?.required"><small>Email es
								<strong>requerido</strong></small></mat-error>
					</div>
					<div>
						<mat-form-field appearance="outline">
							<mat-label>
								<mat-icon matSuffix>lock</mat-icon> Contraseña
							</mat-label>
							<input [type]="inputType" matInput formControlName="password" required>

							<mat-icon matSuffix (click)=changeInputType() class="hand">visibility</mat-icon>
						</mat-form-field>
						<mat-error *ngIf="submitted && formulario.controls.password.errors?.required">
							<small>Contraseña
								<strong>requerida</strong></small></mat-error>
					</div>
				</mat-card-content> -->
				<mat-card-actions>
					<!-- <button mat-raised-button color="primary" type="submit" *ngIf="!IsLoading">Iniciar sesión</button>
					<div class="loading" *ngIf="IsLoading">
						<mat-spinner diameter="30"></mat-spinner>
					</div> -->
					<p>
						<a (click)="navigateToRecoverPass()" mat-menu-item class="text-center">Olvidé mi contraseña</a>
					</p>
				</mat-card-actions>
				<!-- <mat-card-footer>
					<a (click)="goRegister()" mat-menu-item class="text-center">Registrarme</a>
				</mat-card-footer> -->
			</form>
		</mat-card>
	</div>
</div>