import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  constructor(private breakpointObserver: BreakpointObserver) { }

  // thinnerNav$: Observable<boolean> = this.breakpointObserver.observe(['(min-width: 599px)'])
  //   .pipe(
  //     map(result => !result.matches)
  //   );
  thinnerNav$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  ngOnInit() {
    this.breakpointObserver
      .observe(['(min-width: 599px)'])
      .subscribe((state: BreakpointState) => {
        if (!state.matches) {
          this.thinnerNav$.next(true);
        } else {
          this.thinnerNav$.next(false);
        }
      });
  }

}
