<div mat-dialog-title class="dialog-title">
        {{title}}
      </div>
      
      <div mat-dialog-content class="dialog-content">
        <p>{{message}}</p>
      </div>
      
      <div mat-dialog-actions class="dialog-actions text-center d-flex">
          <button mat-raised-button color="primary" class="mx-auto" (click)="onConfirm()">{{dismissBtn}}</button>

      
      </div>
      