import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TituloService {

  private titulo$: BehaviorSubject<string> = new BehaviorSubject<string>("");

  constructor() { }

  getTitulo(): Observable<string> {
    return this.titulo$.asObservable();
  }

  titulo(value: string) {
    this.titulo$.next(value);
  }

}
