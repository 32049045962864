import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IMovimientos, TipoMovimientoFilter, EstadosFilter } from 'src/app/classes/imovimientos';
import { MatDialog } from '@angular/material';
import { DialogoConfirmacionComponent, ConfirmDialogModel } from 'src/app/shared/components/dialogo-confirmacion/dialogo-confirmacion.component';
import { MovimientosService } from '../../../movimientos.service';

@Component({
  selector: 'app-detalle-cobro-link',
  templateUrl: './detalle-cobro-link.component.html',
  styleUrls: ['./detalle-cobro-link.component.scss']
})
export class DetalleCobroLinkComponent implements OnInit {

  constructor(private router: Router, public dialog: MatDialog, private service: MovimientosService) { }

  @Input() detalle: IMovimientos;

  titulo;
  estado;
  colorEstado;

  total;
  screen = 0;
  IsLoading = false;

  ngOnInit() {
    console.log(this.detalle);
    this.titulo = new TipoMovimientoFilter().getLabel(this.detalle.COD_MOV, this.detalle.IMPORTE);
    this.estado = new EstadosFilter().getEstado(this.detalle.ID_ESTADO);
    this.colorEstado = new EstadosFilter().getColor(this.detalle.ID_ESTADO);

  }

  cancelar() {
    this.Confirmacion();
  }

  Confirmacion() {
    const dialogRef = this.dialog.open(DialogoConfirmacionComponent, {
      maxWidth: "400px",
      width: "300px",
      data: new ConfirmDialogModel(
        "Confirmación",
        "¿Desea cancelar la operación pendiente?",
        "Confirmar",
        "Cancelar")
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {

      if (confirmed) {
        this.IsLoading = true;

        this.service.Cancelar(this.detalle.COD_MOV, this.detalle.NUM_MOV).subscribe(x => {
          this.IsLoading = false;
          if (!x.res) {
            this.screen = 2;
            return;
          }

          this.screen = 1;
        });

      }

    });
  }

  back() {

    this.router.navigateByUrl('/movimientos');

  }

  navigateToLogin() {
    this.router.navigateByUrl('/inicio');
  }

}
