import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { RequestClass } from './request.class';
import { BehaviorSubject, Subscription, timer, of, Observable } from 'rxjs';
import { IResponse } from '../classes/iresponse';
import { switchMap, catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService implements IDisposable {


  $saldo: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  $pendienteEntrada: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  $pendienteSalida: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  homeInfosub: Subscription;
  authstatussub: Subscription;

  constructor(private request: RequestClass, private auth: AuthService) {

    this.saldoSub();

    this.authstatussub = this.auth.loginStatus.pipe(
      switchMap((x) => {
        if (x)
          return this.req();
        else {
          return of(null);
        }

      })
    ).subscribe(x => {
      if (x != null) {
        console.log("saldo updatedOnLogin")

        this.$saldo.next(x.data.saldo);
        this.$pendienteEntrada.next(x.data.saldo_a_disponer);
        this.$pendienteSalida.next(x.data.saldo_a_salir);
        // this.saldoSub();
      }
      else {
        console.log("saldo cleared on logout")
        this.$saldo.next(0);
        this.$pendienteEntrada.next(0);
        this.$pendienteSalida.next(0);
        // this.homeInfosub.unsubscribe();
      }

    });

  }

  GetMovimientosTop(onlyTop: number = 1): Observable<any> {
    return this.request.ApiGet(`/movimientos/top/4/0/0/0/` + onlyTop).pipe(catchError(e => {
      return of({ res: false, err: "Se Produjo un error inesperado." });
    }));
  }

  saldoSub() {
    this.homeInfosub = timer(0, 5000).pipe(
      // switchMap(() =>

      //   this.auth.IsLoggedIn()
      // ),
      switchMap((x) => {


        if (this.auth.loginStatus.value) {

          return this.req();
        }

        else
          return of(null)
      })
    ).subscribe(x => {
      if (x != null) {
        console.log("saldo updated");

        this.$saldo.next(x.data.saldo);
        this.$pendienteEntrada.next(x.data.saldo_a_disponer);
        this.$pendienteSalida.next(x.data.saldo_a_salir);
      }
    })
  }


  req() {
    return this.request.ApiGet<IResponse<any>>("/home/info");
  }



  dispose() {
    if (this.homeInfosub)
      this.homeInfosub.unsubscribe();
  }
}
interface IDisposable {
  dispose();
}

function using<T extends IDisposable>(resource: T, func: (resource: T) => void) {
  try {
    func(resource);
  } finally {
    resource.dispose();
  }


}
