import { BehaviorSubject, of } from 'rxjs';
import { Router } from '@angular/router';
import { RequestClass } from './request.class';
import { map, catchError, tap } from 'rxjs/operators';
import { NotifService } from './notif.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./request.class";
import * as i3 from "./notif.service";
export class AuthService {
    constructor(router, request, notif) {
        this.router = router;
        this.request = request;
        this.notif = notif;
        this.loginStatus = new BehaviorSubject(false);
        this.IsLoggedIn().subscribe(x => this.loginStatus.next(x));
    }
    isLocalstoreNotEmpty() {
        return of(localStorage.length != 0);
    }
    IsLoggedIn() {
        let expiration = localStorage.getItem('expiration');
        if (expiration != null && new Date(expiration) >= new Date())
            return of(true).pipe(map(x => {
                this.loginStatus.next(x);
                return x;
            }));
        else
            return this.request.ApiGet("/auth").pipe(map(x => true), catchError(x => of(false)), map(x => {
                this.loginStatus.next(x);
                return x;
            }));
    }
    doLogin(login) {
        login = Object.assign({}, login, { web: true });
        return this.request.ApiPost_Public(`auth/login`, login).pipe(tap(x => {
            if (!x.res)
                throw Error(x.err);
            this.setToken(x.data.session);
            this.setSessionInfo(x.data.user);
            this.setVariables(x.data.variables);
            this.loginStatus.next(true);
            this.notif.success("La sesión se ha iniciado correctamente", "Fértil Suma", 3000);
            this.router.navigateByUrl("/inicio");
        }, error => {
            this.loginStatus.next(false);
            console.log("LoginError:", error);
            if (error.status == 401) {
                this.notif.warning("Los datos ingresados son inválidos.", "Error", 3000, false, false, "toast-top-center");
            }
            else {
                this.notif.error("Se produjo un error inesperado al intentar iniciar la sesión.", "Error", 3000, false, false, "toast-top-center");
            }
        }));
    }
    doLogout() {
        this.loginStatus.next(false);
        localStorage.clear();
        this.router.navigateByUrl("/login");
        return of();
    }
    getToken() {
        return localStorage.getItem('token');
    }
    setToken(session) {
        localStorage.setItem('token', session.token);
        localStorage.setItem('expiration', session.expiration);
    }
    setVariables(variables) {
        localStorage.setItem('variables', variables);
    }
    getSessionInfo() {
        let userData = localStorage.getItem("sessionInfo");
        if (!userData)
            return null;
        return JSON.parse(userData);
    }
    setSessionInfo(userData) {
        localStorage.setItem("sessionInfo", JSON.stringify(userData));
    }
    SaveRecoverPass(k, password) {
        let accountData = {
            key: k,
            password: password
        };
        return this.request.ApiPost_Public("/auth/update_password", accountData).pipe(catchError(e => {
            return of({ res: false, err: "Se produjo un error inesperado." });
        }));
    }
    RecoverAcount(k, dni) {
        let accountData = {
            email: k,
            dni: dni
        };
        return this.request.ApiPost_Public("/auth/recover_password", accountData).pipe(catchError(e => {
            return of({ res: false, err: "Se produjo un error inesperado." });
        }));
    }
    ActivateAccount(k) {
        return this.request.ApiGet_Public("/auth/validate/" + k).pipe(catchError(e => {
            return of({ res: false, err: "Se produjo un error inesperado." });
        }));
    }
    RegretBaja(k) {
        debugger;
        return this.request.ApiPost_Public("/baja-cuenta/user-arrepentimiento/", { regretCode: k }).pipe(catchError(e => {
            return of({ res: false, err: "Se produjo un error inesperado." });
        }));
    }
    SendDni(k) {
        debugger;
        return this.request.ApiGet_Public("auth/sissa/" + k.dni + "/" + k.sexo).pipe(catchError(e => {
            return of({ res: false, err: "Se produjo un error inesperado." });
        }));
    }
    GetLocalidades() {
        return this.request.ApiGet_Public("localidades/localidades").pipe(catchError(e => {
            return of({ res: false, err: "Se produjo un error inesperado." });
        }));
    }
    RegisterAccount(accountData) {
        return this.request.ApiPost_Public("/auth/signup", accountData).pipe(catchError(e => {
            return of({ res: false, err: "Se produjo un error inesperado." });
        }));
    }
    SendCuit(k) {
        return this.request.ApiGet_Public("auth/signup_pj/get_persona/" + k).pipe(catchError(e => {
            return of({ res: false, err: "Se produjo un error inesperado." });
        }));
    }
    VerifyEmailAndPhoneAndSendCode(k) {
        return this.request.ApiPost_Public("auth/signup_pj/submit_1", k).pipe(catchError(e => {
            return of({ res: false, err: "Se produjo un error inesperado." });
        }));
    }
    SignupEmpresa(k) {
        return this.request.ApiPost_Public("auth/signup_pj/submit_2", k).pipe(catchError(e => {
            return of({ res: false, err: "Se produjo un error inesperado." });
        }));
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.RequestClass), i0.ɵɵinject(i3.NotifService)); }, token: AuthService, providedIn: "root" });
