<ng-container [ngSwitch]=screen>
    <ng-container *ngSwitchCase=0>
        <div class="ContainerWallet">
            <h1>{{titulo}}</h1>
            <h4 [ngStyle]="{color: colorEstado}">{{estado}}</h4>
            <mat-card class="WalletCard descripcion">

                <mat-card-content>
                    <p>
                        <b>Descripción<span *ngIf="detalle.MOTIVO">/ Motivo</span>:</b> {{detalle.CONCEPTO}}
                        <span class="motivo" *ngIf="detalle.MOTIVO"> {{detalle.MOTIVO}}</span>
                    </p>



                </mat-card-content>
            </mat-card>
            <div class="fecha">
                <mat-label>Pagado el:</mat-label>
                <div class="w-100"></div><span>{{detalle.FECHA | date:'dd/MM/yyyy'}}</span>
            </div>

            <mat-card class="WalletCard importe">

                <mat-card-content>
                    <b>Importe: </b>{{detalle.IMPORTE * -1 | currency:"$":"symbol"}}
                </mat-card-content>
            </mat-card>

            <p> N° de Operación {{detalle.NUM_MOV}}</p>

            <button mat-raised-button color="primary" *ngIf="detalle.ID_ESTADO==2 && !IsLoading"
                (click)=cancelar()>Cancelar
                operación</button>
            <button mat-raised-button color="primary" *ngIf="!IsLoading" (click)=back()>Atrás</button>
            <div class="loading" *ngIf="IsLoading">
                <mat-spinner diameter="30"></mat-spinner>
            </div>

        </div>
    </ng-container>

    <ng-container *ngSwitchCase=1>
        <div class="ContainerWallet">
            <mat-card class="WalletCardImages">

                <img mat-card-image src="assets/exito.png">

                <mat-card-header>
                    <mat-card-title><b>La operación se canceló correctamente.</b></mat-card-title>
                </mat-card-header>

                <mat-card-actions>
                    <button mat-raised-button color="primary" type="button" (click)="navigateToLogin()">Ir al
                        inicio</button>

                </mat-card-actions>
            </mat-card>
        </div>

    </ng-container>

    <ng-container *ngSwitchCase=2>
        <div class="ContainerWallet">
            <mat-card class="WalletCardImages">

                <img mat-card-image src="assets/error.png">

                <mat-card-header>
                    <mat-card-title><b>No se pudo cancelar operación.</b></mat-card-title>
                </mat-card-header>

                <mat-card-actions>
                    <button mat-raised-button color="primary" type="button" (click)="navigateToLogin()">
                        Ir al inicio
                    </button>
                </mat-card-actions>
            </mat-card>
        </div>

    </ng-container>
</ng-container>