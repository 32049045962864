<button mat-button [matMenuTriggerFor]="beforeMenu" class="UserMenu">
  <div class="d-flex">
    <span class="username">{{getNombre()}}</span>
    <!-- <img class="img" src="assets/default_user.png"> -->
    <!-- <img class="img" [attr.src]="ImgUrl"> -->
    <div class="d-flex">
      <mat-icon class="my-auto">account_circle</mat-icon>
    </div>

  </div>

</button>
<mat-menu #beforeMenu="matMenu" class="matUserMenu" xPosition="before" color=primary>
  <!-- <button mat-menu-item class="menuitem">Perfil</button>
  <button mat-menu-item class="menuitem">Configuración</button> -->
  <button mat-menu-item class="menuitem" (click)=" DoLogout()">Cerrar sesión</button>
</mat-menu>