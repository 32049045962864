import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/services/home.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-saldo',
  templateUrl: './saldo.component.html',
  styleUrls: ['./saldo.component.scss']
})
export class SaldoComponent implements OnInit {

  saldo: number = 0;
  sub: Subscription;


  constructor(private service: HomeService) { }

  ngOnInit() {
    this.sub = this.service.$saldo.subscribe(x => this.saldo = x);

  }

  ngOnDestroy() {
    if (this.sub)
      this.sub.unsubscribe();
  }
}
