import { Component, OnInit, Input } from '@angular/core';
import { IMovimientos, TipoMovimientoFilter, EstadosFilter } from 'src/app/classes/imovimientos';
import { Router } from '@angular/router';

@Component({
  selector: 'app-detalle-cobro-qr',
  templateUrl: './detalle-cobro-qr.component.html',
  styleUrls: ['./detalle-cobro-qr.component.scss']
})
export class DetalleCobroQRComponent implements OnInit {

  constructor(private router: Router) { }

  @Input() detalle: IMovimientos;

  titulo;
  estado;
  colorEstado;
  fechaLabel;
  total;

  ngOnInit() {

    this.titulo = new TipoMovimientoFilter().getLabel(this.detalle.COD_MOV, this.detalle.IMPORTE);
    this.estado = new EstadosFilter().getEstado(this.detalle.ID_ESTADO);
    this.colorEstado = new EstadosFilter().getColor(this.detalle.ID_ESTADO);
    this.fechaLabel = this.detalle.IMPORTE > 0 ? "Cobrado el:" : "Pagado el:";
    this.total = this.detalle.IMPORTE > 0 ? this.detalle.IMPORTE - this.detalle.CARGOS : this.detalle.IMPORTE * -1;

  }

  back() {

    this.router.navigateByUrl('/movimientos');

  }
}
