<div class="item-container" [class.no-divider]="isLast" [class.isChild]="isChild">
    <!-- Colapsado -->
    <div *ngIf="!item.expanded">
        <div class="item-flex" matRipple [matRippleCentered]="false" (click)="onTap()" [class.activo]="activo(item)">
            <div class="item-icon" *ngIf=!isChild>
                <img src={{item.icon}}>
            </div>
            <div class="item-label">{{item.label}}</div>
            <div class="item-toggle-expand" *ngIf="item.children.length > 0">
                <mat-icon>expand_more</mat-icon>
            </div>
        </div>
    </div>

    <!-- Expandido -->
    <div *ngIf="item.expanded">

        <div class="item-flex item-header" (click)="collapse()">
            <div class="item-icon">
                <img src={{item.icon}}>
            </div>
            <div class="item-label">{{item.label}}</div>
            <div class="item-toggle-expand">
                <mat-icon>expand_less</mat-icon>
            </div>
        </div>
        <ng-container *ngFor="let child of item.children; let i = index">
            <app-menu-item [item]=child [isLast]="(i+1 == item.children.length)" [isChild]=true></app-menu-item>
        </ng-container>


    </div>
</div>