import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-saldo-salida',
  templateUrl: './saldo-salida.component.html',
  styleUrls: ['../saldo/saldo.component.scss']
})
export class SaldoSalidaComponent implements OnInit {

  pendienteSalida: number = 0;
  sub: Subscription;

  constructor(private service: HomeService) { }

  ngOnInit() {
    this.sub = this.service.$pendienteSalida.subscribe(x => {

      this.pendienteSalida = x;
    });
  }

  ngOnDestroy() {
    if (this.sub)
      this.sub.unsubscribe();
  }

}
