import { Component, OnInit, Input } from '@angular/core';
import { TipoMovimientoFilter, EstadosFilter, IMovimientos } from 'src/app/classes/imovimientos';
import { Router } from '@angular/router';

@Component({
  selector: 'app-detalle-carga-saldo',
  templateUrl: './detalle-carga-saldo.component.html',
  styleUrls: ['./detalle-carga-saldo.component.scss']
})
export class DetalleCargaSaldoComponent implements OnInit {

  constructor(private router: Router) { }

  @Input() detalle: IMovimientos;

  titulo;
  estado;
  colorEstado;
  fechaLabel;
  total;

  ngOnInit() {
    console.log(this.detalle);
    this.titulo = new TipoMovimientoFilter().getLabel(this.detalle.COD_MOV, this.detalle.IMPORTE);
    this.estado = new EstadosFilter().getEstado(this.detalle.ID_ESTADO);
    this.colorEstado = new EstadosFilter().getColor(this.detalle.ID_ESTADO);
  }

  back() {

    this.router.navigateByUrl('/movimientos');

  }
}
