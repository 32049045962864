import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HomeService } from 'src/app/services/home.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-saldo-entrada',
  templateUrl: './saldo-entrada.component.html',
  styleUrls: ['../saldo/saldo.component.scss']
})
export class SaldoEntradaComponent implements OnInit {

  pendienteEntrada: number = 0;
  sub: Subscription;

  constructor(private service: HomeService, private router: Router) { }

  ngOnInit() {
    this.sub = this.service.$pendienteEntrada.subscribe(x => {

      this.pendienteEntrada = x;
    });
  }

  ngOnDestroy() {
    if (this.sub)
      this.sub.unsubscribe();
  }
  goTo(path: string) {
    this.router.navigateByUrl("pages/movimientos");
  }

}
