import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DialogoAlertComponent, AlertDialogModel } from 'src/app/shared/components/dialogo-alert/dialogo-alert.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  IsLoading: boolean = false;
  formulario: FormGroup;
  submitted: boolean = false;
  inputType: string = "password";
  inputTypeState: boolean = false;

  public onSubmit(): Observable<any> {

    this.submitted = true;

    if (!this.formulario.valid) {
      return;
    }

    this.IsLoading = true;
    this.auth.doLogin(this.formulario.value).subscribe(x => {
      this.IsLoading = false;
    }, err => {

      this.IsLoading = false;
      let msg = "Se produjo un error inesperado.";
      if (err.message == "user_not_activated")
        msg = "Debe activar su cuenta.";
      if (err.message == "invalid_credentials")
        msg = "Los datos ingresados son inválidos.";
      if (err.message == "invalid_email")
        msg = "El email ingresado no es válido, o no pertenece a un usuario registrado.";
      if (err.message == "invalid_password")
        msg = "La contraseña ingresada es incorrecta.";
      this.ShowAlert2(msg);
    });


  }


  constructor(public dialog: MatDialog, private router: Router, private auth: AuthService, private dhd: Router, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.formulario = this.formBuilder.group({
      login: ["", [Validators.required, Validators.email],],
      password: ["", Validators.required],
    }, { updateOn: "submit" });

  }

  goRegister() {
    this.dhd.navigateByUrl("register");
  };

  navigateToRecoverPass() {
    this.router.navigateByUrl('/recover_password');
  }

  changeInputType() {

    if (this.inputTypeState) {
      this.inputTypeState = false;
      this.inputType = "password";
    }
    else {
      this.inputTypeState = true;
      this.inputType = "text";
    }

  }

  ShowAlert2(msg, title = "Error") {
    const dialogRef = this.dialog.open(DialogoAlertComponent, {
      maxWidth: "400px",
      width: "300px",
      data: new AlertDialogModel(
        "",
        msg)
    });
    return dialogRef.afterClosed();
  }

  gotoPlayStore() {
    window.location.href = "https://play.google.com/store/apps/details?id=com.ayj.wallet";
  }

}
