import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IMovimientos, TipoMovimientoFilter, EstadosFilter } from 'src/app/classes/imovimientos';

@Component({
  selector: 'app-detalle-recarga-sube',
  templateUrl: './detalle-recarga-sube.component.html',
  styleUrls: ['./detalle-recarga-sube.component.scss']
})
export class DetalleRecargaSubeComponent implements OnInit {

  constructor(private router: Router) { }

  @Input() detalle: IMovimientos;

  titulo;
  estado;
  colorEstado;
  total;
  medioPago;

  ngOnInit() {
    console.log(this.detalle);
    this.titulo = new TipoMovimientoFilter().getLabel(this.detalle.COD_MOV, this.detalle.IMPORTE);
    this.estado = new EstadosFilter().getEstado(this.detalle.ID_ESTADO);
    this.colorEstado = new EstadosFilter().getColor(this.detalle.ID_ESTADO);

    switch (this.detalle.ID_MEDIO_PAGO) {

      case 2: this.medioPago = "Tarjeta Fértil";

        break;
      case 3: this.medioPago = "FértilCred";

        break;


      default: this.medioPago = "Fértil Suma";
        break;
    }

  }

  back() {

    this.router.navigateByUrl('/movimientos');

  }

}
