import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { FaModule } from './fa.module';
import { DrawerContentComponent } from './components/drawer-content/drawer-content.component';
import { NavbarcontentComponent } from './components/navbarcontent/navbarcontent.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogoAlertComponent } from './components/dialogo-alert/dialogo-alert.component';
import { DialogoConfirmacionComponent } from './components/dialogo-confirmacion/dialogo-confirmacion.component';
import { SlideShowComponent } from './components/slide-show/slide-show.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { ItemComponent } from '../pages/movimientos/components/item/item.component';
import { BarSaldoComponent } from './components/bar-saldo/bar-saldo.component';
import { LayoutComponent } from './components/layout.component';

const ExportModules = [
  FormsModule,
  ReactiveFormsModule,
  MaterialModule, FaModule, PerfectScrollbarModule, NgImageSliderModule, MatCarouselModule
]

const ExportComponents = [
  DrawerContentComponent,
  NavbarcontentComponent,
  UserMenuComponent,
  DialogoAlertComponent,
  DialogoConfirmacionComponent,
  SlideShowComponent,
  MenuItemComponent,
  ItemComponent,
  BarSaldoComponent,
  LayoutComponent
]


@NgModule({
  declarations: [
    ExportComponents


  ],
  imports: [
    CommonModule,
    ExportModules
  ],
  exports: [
    ExportModules,
    ExportComponents
  ]
})
export class SharedModule { }
