import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slide-show',
  templateUrl: './slide-show.component.html',
  styleUrls: ['./slide-show.component.scss']
})
export class SlideShowComponent implements OnInit {

  imageObject: Array<object> = [{
    image: '/assets/final-04.jpg',
    // thumbImage: '/assets/login01.jpg',
    alt: 'alt of image'
  }, {
    image: '/assets/final-05.jpg',
    // thumbImage: '/assets/ubuntu2.jpg',
    alt: 'alt of image'
  }, {
    image: '/assets/final-06.jpg',
    // thumbImage: '/assets/ubuntu3.jpg',
    alt: 'alt of image'
  }
  ];

  constructor() { }

  ngOnInit() {

  }

}
