<ng-container [ngSwitch]=data?.COD_MOV>
    <ng-container *ngSwitchCase=20>
        <app-detalle-envio-dinero [detalle]=data></app-detalle-envio-dinero>
    </ng-container>
    <ng-container *ngSwitchCase=38>
        <app-detalle-ingreso-prestamo [detalle]=data></app-detalle-ingreso-prestamo>
    </ng-container>
    <ng-container *ngSwitchCase=22>
        <app-detalle-cobro-qr [detalle]=data>
        </app-detalle-cobro-qr>
    </ng-container>
    <ng-container *ngSwitchCase=24>
        <app-detalle-retiro-sucursal [detalle]=data></app-detalle-retiro-sucursal>
    </ng-container>
    <ng-container *ngSwitchCase=10>
        <app-detalle-carga-saldo [detalle]=data></app-detalle-carga-saldo>
    </ng-container>
    <ng-container *ngSwitchCase=40>
        <app-detalle-cobro-link [detalle]=data></app-detalle-cobro-link>
    </ng-container>
    <ng-container *ngSwitchCase=33>
        <app-detalle-recarga-sube [detalle]=data></app-detalle-recarga-sube>
    </ng-container>
    <ng-container *ngSwitchCase=32>
        <app-detalle-recarga-sube [detalle]=data></app-detalle-recarga-sube>
    </ng-container>

</ng-container>