<div class="legacyScrollWrapper">
    <perfect-scrollbar class="purfectScroll">
        <div class="wrapper">
            <div class="filtros">
                <mat-card class="filterCard">
                    <div class="filtersRow">
                        <mat-form-field class=" w-100" appearance="outline">
                            <mat-label>Período:</mat-label>
                            <mat-select [(ngModel)]=currentPeriodo (selectionChange)="ExecuteQuery()">
                                <mat-option *ngFor="let item of filtroPeriodo" value="{{item.value}}">{{item.nombre}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class=" w-100" appearance="outline">
                            <mat-label>Ingreso/Egreso:</mat-label>
                            <mat-select [(ngModel)]=currentTipoMov (selectionChange)="ExecuteQuery()">
                                <mat-option *ngFor="let item of filtroTipoMov" value="{{item.value}}">{{item.nombre}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class=" w-100" appearance="outline">
                            <mat-label>Colaborador:</mat-label>
                            <mat-select [(ngModel)]=currentColaborador (selectionChange)="ExecuteQuery()">
                                <mat-option *ngFor="let item of filtroColaborador" value="{{item.ID}}">
                                    {{item.nombre}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="filtersRow filtersRow2">
                        <mat-form-field class=" w-100" appearance="outline">
                            <mat-label>Tipo de operación:</mat-label>
                            <mat-select [(ngModel)]=currentMovimiento (selectionChange)="ExecuteQuery()">
                                <mat-option *ngFor="let item of filtroMovimiento" value="{{item.value}}">
                                    {{item.apellido}}
                                    {{item.nombre}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class=" w-100" appearance="outline">
                            <mat-label>Estado de operación:</mat-label>
                            <mat-select [(ngModel)]=currentEstado (selectionChange)="ExecuteQuery()">
                                <mat-option *ngFor="let item of filtroEstado" value="{{item.value}}">{{item.nombre}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <mat-card-actions>
                        <button mat-raised-button color="primary" (click)="limpiarFlitros()"
                            *ngIf="currentMovimiento != '0' || currentEstado != '0' || currentPeriodo != '7' || currentTipoMov != '0' || currentColaborador != '0'">Limpiar
                            filtros</button>
                    </mat-card-actions>
                </mat-card>
            </div>
            <div class="movimientos">

                <mat-card>
                    <table mat-table [dataSource]="dataSource">
                        <ng-container matColumnDef="position">
                            <td mat-cell *matCellDef="let element">
                                <app-item [movi]=element (click)=getDetalle(element.ID)></app-item>
                            </td>
                        </ng-container>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </mat-card>

            </div>

        </div>
    </perfect-scrollbar>
</div>




<!-- <div class="Grid-container">
    <mat-card class=" filterCard">
        <div>
            <mat-form-field class=" w-100" appearance="outline">
                <mat-label>Periodo:</mat-label>
                <mat-select [(ngModel)]=currentPeriodo (selectionChange)="ExecuteQuery()">
                    <mat-option *ngFor="let item of filtroPeriodo" value="{{item.value}}">{{item.nombre}}
                    </mat-option>
                </mat-select>
            </mat-form-field>


            <mat-form-field class=" w-100" appearance="outline">
                <mat-label>Tipo:</mat-label>
                <mat-select [(ngModel)]=currentTipoMov (selectionChange)="ExecuteQuery()">
                    <mat-option *ngFor="let item of filtroTipoMov" value="{{item.value}}">{{item.nombre}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="ml-3">
            <mat-form-field class=" w-100" appearance="outline">
                <mat-label>Movimiento:</mat-label>
                <mat-select [(ngModel)]=currentMovimiento (selectionChange)="ExecuteQuery()">
                    <mat-option *ngFor="let item of filtroMovimiento" value="{{item.value}}">{{item.nombre}}
                    </mat-option>
                </mat-select>
            </mat-form-field>


            <mat-form-field class=" w-100" appearance="outline">
                <mat-label>Estado:</mat-label>
                <mat-select [(ngModel)]=currentEstado (selectionChange)="ExecuteQuery()">
                    <mat-option *ngFor="let item of filtroEstado" value="{{item.value}}">{{item.nombre}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <mat-card-actions>
            <button mat-raised-button color="primary" (click)="limpiarFlitros()" *ngIf="currentMovimiento != '0' || currentEstado != '0' || currentPeriodo != '0' || currentTipoMov != '0'">Limpiar
                filtros</button>
        </mat-card-actions>


    </mat-card>
    <mat-card class="GridCard">
        <div class="scrollArea">
            <perfect-scrollbar class="purfectScroll" [config]=scrollbarconfig>
                <table mat-table [dataSource]="dataSource">

                    
                    <ng-container matColumnDef="position">
                        <td mat-cell *matCellDef="let element">
                            <app-item [movi]=element></app-item>
                        </td>
                    </ng-container>

                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </perfect-scrollbar>
        </div>


    </mat-card>
</div> -->