import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-bar-saldo',
  templateUrl: './bar-saldo.component.html',
  styleUrls: ['./bar-saldo.component.scss']
})
export class BarSaldoComponent implements OnInit {

  saldo: number;
  entrada: number;
  salida: number;

  constructor(private home: HomeService) { }

  ngOnInit() {

    this.home.$saldo.subscribe(x => this.saldo = x);
    this.home.$pendienteEntrada.subscribe(x => this.entrada = x);
    this.home.$pendienteSalida.subscribe(x => this.salida = x);
  }
}
