<app-layout class="layout">
  <div navbar class="w-100 d-flex">
    <app-navbarcontent [Title]="Title" class="d-flex w-100 align-items-center">
      <div>
        <app-bar-saldo *ngIf="Title != 'Inicio'"></app-bar-saldo>
      </div>
      <div>
        <app-user-menu></app-user-menu>
      </div>

    </app-navbarcontent>
  </div>
  <div drawer>
    <app-drawer-content></app-drawer-content>
  </div>
  <div body>
    <router-outlet></router-outlet>
  </div>

</app-layout>