<div id="publicContainer">
  <div class="slideshowContainer">

    <app-slide-show></app-slide-show>
  </div>
  <div class="formContainer">
    <mat-card class="mat-elevation-z0">
      <ng-container [ngSwitch]="screen">
        <ng-container *ngSwitchCase="1">
          <mat-card-header>
            <mat-card-title>
              <mat-spinner></mat-spinner>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <b>Cargando...</b>
          </mat-card-content>
        </ng-container>
        <ng-container *ngSwitchCase="2">
          <img class="img-msg" src="assets/exito.png">
          <mat-card-header>
            <mat-card-title><b>La contraseña se actualizó correctamente</b></mat-card-title>
          </mat-card-header>
          <mat-card-actions>
            <button mat-raised-button color="primary" type="button" (click)="navigateToLogin()">Iniciar sesión</button>
          </mat-card-actions>
        </ng-container>
        <ng-container *ngSwitchCase="3">
          <img class="img-msg" src="assets/error.png">
          <mat-card-header>
            <mat-card-title><b>No hemos podido modificar tu contraseña</b></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            Inténtelo más tarde.
          </mat-card-content>
          <mat-card-actions>
            <button mat-raised-button color="primary" type="button" (click)="navigateToLogin()">Iniciar sesión</button>
          </mat-card-actions>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <form #F [formGroup]="myForm" (ngSubmit)="submitForm()" novalidate>
            <img mat-card-image src="/assets/LogoWallet.png" class="form-img">
            <mat-card-header>
              <mat-card-subtitle>Recuperar contraseña</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <div>
                <mat-form-field appearance="outline">
                  <mat-label>
                    <mat-icon matSuffix>lock</mat-icon> Nueva contraseña
                  </mat-label>
                  <input [type]=inputType matInput placeholder="Nueva contraseña" #password formControlName="password"
                    autocomplete="off">

                  <mat-icon matSuffix (click)=changeInputType() class="hand">visibility</mat-icon>
                </mat-form-field>
                <mat-error *ngIf="submitted && myForm.controls.password.errors?.required"><small>Ingreso
                    obligatorio.</small></mat-error>
                <mat-error
                  *ngIf="submitted && myForm.controls.password.errors?.pattern && !myForm.controls.password.errors?.required">
                  <small>Debe tener como
                    mínimo 6 caracteres y al menos 1 mayúscula, 1 minúscula y 1 número.</small></mat-error>
              </div>

              <div>
                <mat-form-field appearance="outline">
                  <mat-label>
                    <mat-icon matSuffix>lock</mat-icon> Repita contraseña
                  </mat-label>
                  <input [type]=inputTypeRepeat matInput placeholder="Repetí la nueva contraseña" #confirmPassword
                    formControlName="confirmPassword" autocomplete="off">

                  <mat-icon matSuffix (click)=changeInputTypeRepeat() class="hand">visibility</mat-icon>
                </mat-form-field>
                <mat-error *ngIf="submitted && myForm.controls.confirmPassword.errors?.required"><small>Ingreso
                    obligatorio.</small></mat-error>
                <mat-error
                  *ngIf="submitted && myForm.controls.confirmPassword.errors?.mustMatch && !myForm.controls.confirmPassword.errors?.required">
                  <small>La
                    contraseña debe coincidir.</small></mat-error>
              </div>
            </mat-card-content>
            <mat-card-actions>
              <button mat-raised-button color="primary" *ngIf="!IsLoading">Guardar contraseña</button>
              <div class="loading" *ngIf="IsLoading">
                <mat-spinner diameter="30"></mat-spinner>
              </div>
            </mat-card-actions>
          </form>
        </ng-container>
      </ng-container>
    </mat-card>
  </div>
</div>