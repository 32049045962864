<img src="assets/LogoWallet_blanco.png" alt="Fértil Suma" height="50">

<span class="ml-3">{{Title}}</span>

<div class="mx-auto"></div>
<span class="ml-auto">
  <div class="d-flex">
    <ng-content></ng-content>
  </div>

</span>