import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { RequestClass } from 'src/app/services/request.class';
import { MatDialog } from '@angular/material/dialog';
import { DialogoAlertComponent, AlertDialogModel } from 'src/app/shared/components/dialogo-alert/dialogo-alert.component';

@Component({
  selector: 'app-validate-recover-password',
  templateUrl: './validate-recover-password.component.html',
  styleUrls: ['./validate-recover-password.component.scss']
})
export class ValidateRecoverPasswordComponent implements OnInit {

  IsLoading: boolean = false;
  formulario: FormGroup;
  submitted: boolean = false;

  onSubmit() {

    this.submitted = true;

    if (!this.formulario.valid) {
      return;
    }

    this.IsLoading = true;
    this.auth.RecoverAcount(this.formulario.controls.login.value, this.formulario.controls.dni.value).subscribe(x => {

      this.IsLoading = false;
      try {
        if (!x.res)
          throw Error(x.err);

        this.ShowAlert2(`Se envió un email a ${this.formulario.controls.login.value} con las instrucciones para recuperar la contraseña.`);
        this.router.navigateByUrl('/login');
      } catch (err) {

        console.log(err);
        let msg = "Se produjo un error inesperado.";
        if (err.message == "invalid_dni")
          msg = "El DNI ingresado no coinicide con el usuario registrado.";
        if (err.message == "invalid_email")
          msg = "El Email ingresado no pertenece a un usuario registrado.";


        this.ShowAlert2(msg);
      }

    }, err => {

      this.IsLoading = false;
      let msg = "Se produjo un error inesperado.";
      this.ShowAlert2(msg);
    });


  }



  constructor(public dialog: MatDialog, private router: Router, private auth: AuthService, private dhd: Router, private req: RequestClass, private breakpointObserver: BreakpointObserver, private formBuilder: FormBuilder) { }

  ngOnInit() {

    this.formulario = this.formBuilder.group({
      login: ["", [Validators.required, Validators.email],],
      dni: ["", Validators.required],
    }, { updateOn: "submit" });


  }
  ShowAlert2(msg, title = "Error") {
    const dialogRef = this.dialog.open(DialogoAlertComponent, {
      maxWidth: "400px",
      width: "400px",
      data: new AlertDialogModel(
        "",
        msg)
    });
    return dialogRef.afterClosed();
  }
  // ShowConfirmacion(msg, title = "Confirmar"): Observable<any> {
  //   const ref = this.dialog.open(DialogoConfirmacionComponent, {
  //     maxWidth: "400px",
  //     width: "300px",
  //     data: new ConfirmDialogModel(
  //       title,
  //       msg)
  //   })
  //   return ref.afterClosed();
  // }


  navigateToLogin() {
    this.router.navigateByUrl('/login');
  }

  goRegister() {
    this.dhd.navigateByUrl("register");
  };
}
