<div class="content">
    <mat-card class="mat-elevation-z1">
        <div class="cardAbs">
            <mat-card-title class="miniCardTitle">Movimientos recientes</mat-card-title>

            <div class="cardRel">
                <perfect-scrollbar class="purfectScroll">
                    <table mat-table [dataSource]="dataSource">
                        <ng-container matColumnDef="position">
                            <td mat-cell *matCellDef="let element">
                                <app-item [movi]=element></app-item>
                            </td>
                        </ng-container>

                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </perfect-scrollbar>
            </div>
            <mat-card-actions>
                <button mat-raised-button color="accent" (click)=verMas()>Ver más</button>
            </mat-card-actions>
        </div>

    </mat-card>
</div>