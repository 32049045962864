import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

enum RecoverScreen {
  form, loading, success, error
}



@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {

  myForm: FormGroup;
  k: string = "";
  screen: RecoverScreen = RecoverScreen.form;
  submitted: boolean = false;
  inputType: string = "password";
  inputTypeState: boolean = false;
  inputTypeRepeat: string = "password";
  inputTypeStateRepeat: boolean = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );
  constructor(
    private router: Router,
    private formBuilder: FormBuilder, private ar: ActivatedRoute,
    private auth: AuthService, private breakpointObserver: BreakpointObserver) { }

  ngOnInit() {
    this.initForm();
    this.ar.params.subscribe(x => {
      this.k = x["k"];
      this.initForm();
    })


  }

  initForm() {
    this.myForm = this.formBuilder.group({
      key: [this.k],
      password: ['', [Validators.required, Validators.pattern("^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}"), Validators.minLength(6), Validators.maxLength(20)]],
      confirmPassword: ['', Validators.required]
    }, {
      validators: MustMatch('password', 'confirmPassword'),
      updateOn: "submit"
    });
  }


  submitForm() {

    this.submitted = true;

    let form = this.myForm.value;
    if (!this.myForm.valid) {
      return;
    }
    this.screen = RecoverScreen.loading;

    this.auth.SaveRecoverPass(form["key"], form["password"]).subscribe(r => {
      if (!r.res) {
        this.screen = RecoverScreen.error;
        return;
      }

      this.screen = RecoverScreen.success;
      // this.notif.success("La Contraseña se guardo correctamente.", "");
      // this.router.navigate(["login"]);
    }, err => {
      this.screen = RecoverScreen.error;
    });


  }

  navigateToLogin() {
    this.router.navigateByUrl('/login');
  }

  changeInputType() {

    if (this.inputTypeState) {
      this.inputTypeState = false;
      this.inputType = "password";
    }
    else {
      this.inputTypeState = true;
      this.inputType = "text";
    }

  }
  changeInputTypeRepeat() {

    if (this.inputTypeStateRepeat) {
      this.inputTypeStateRepeat = false;
      this.inputTypeRepeat = "password";
    }
    else {
      this.inputTypeStateRepeat = true;
      this.inputTypeRepeat = "text";
    }

  }

  password(formGroup: FormGroup) {

    const { value: password } = formGroup.get('password');
    const { value: confirmPassword } = formGroup.get('confirmPassword');
    return password === confirmPassword ? null : { passwordNotMatch: true };

  }

}
export function MustMatch(controlName: string, matchingControlName: string) {

  return (formGroup: FormGroup) => {

    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}
