import { Component, OnInit } from '@angular/core';
import { MovimientosService } from './movimientos.service';
import { TipoMovimientoFilter, EstadosFilter, PeriodoFilter, TipoFilter } from 'src/app/classes/imovimientos';
import { RouterModule, Router } from '@angular/router';

@Component({
  selector: 'app-movimientos',
  templateUrl: './movimientos.component.html',
  styleUrls: ['./movimientos.component.scss']
})
export class MovimientosComponent implements OnInit {

  displayedColumns = ['position'];
  dataSource: any;
  currentMovimiento = "0";
  currentEstado = "0";
  currentPeriodo = "7";
  currentTipoMov = "0";
  currentColaborador = "0";

  filtroMovimiento: Array<any> = [];
  filtroEstado: Array<any> = [];
  filtroPeriodo: Array<any> = [];
  filtroTipoMov: Array<any> = [];
  filtroColaborador: Array<any> = [];

  constructor(private movimientos: MovimientosService, private router: Router) { }

  ngOnInit() {

    this.filtroMovimiento = new TipoMovimientoFilter().opciones;
    this.filtroEstado = new EstadosFilter().opciones;
    this.filtroPeriodo = new PeriodoFilter().opciones;
    this.filtroTipoMov = new TipoFilter().opciones;
    this.movimientos.GetColaboradores().subscribe(x => {
      
      this.filtroColaborador = [...[{ ID: 0, nombre: "Todos" }], ...x.data];



    });

    let filtros = localStorage.getItem("filtros");

    if (filtros) {
      let object = JSON.parse(filtros);
      this.currentMovimiento = object.currentMovimiento;
      this.currentEstado = object.currentEstado;
      this.currentPeriodo = object.currentPeriodo;
      this.currentTipoMov = object.currentTipoMov;
      this.currentColaborador = object.currentColaborador;
    }

    this.ExecuteQuery();

  }

  ExecuteQuery() {

    this.movimientos.GetMovimientos(this.currentPeriodo, this.currentMovimiento, this.currentEstado, this.currentTipoMov, this.currentColaborador).subscribe(x => {
      this.dataSource = x.data;
    })

    let filtros = {
      currentMovimiento: this.currentMovimiento,
      currentEstado: this.currentEstado,
      currentPeriodo: this.currentPeriodo,
      currentTipoMov: this.currentTipoMov,
      currentColaborador: this.currentColaborador
    }

    localStorage.setItem("filtros", JSON.stringify(filtros));
  }

  limpiarFlitros() {
    this.currentMovimiento = "0";
    this.currentEstado = "0";
    this.currentPeriodo = "7";
    this.currentTipoMov = "0";
    this.currentColaborador = "0";
    this.ExecuteQuery();

    localStorage.removeItem("filtros");
  }


  getDetalle(id: number) {
    
    this.router.navigateByUrl('/movimientos/' + id);

  }

}