/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./drawer-content.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../menu-item/menu-item.component.ngfactory";
import * as i3 from "../menu-item/menu-item.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./drawer-content.component";
var styles_DrawerContentComponent = [i0.styles];
var RenderType_DrawerContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DrawerContentComponent, data: {} });
export { RenderType_DrawerContentComponent as RenderType_DrawerContentComponent };
function View_DrawerContentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-menu-item", [], null, null, null, i2.View_MenuItemComponent_0, i2.RenderType_MenuItemComponent)), i1.ɵdid(2, 114688, null, 0, i3.MenuItemComponent, [i4.Router, i4.ActivatedRoute], { item: [0, "item"], isLast: [1, "isLast"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = ((_v.context.index + 1) == _co.items.length); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_DrawerContentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "menuContainer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DrawerContentComponent_1)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_DrawerContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-drawer-content", [], null, null, null, View_DrawerContentComponent_0, RenderType_DrawerContentComponent)), i1.ɵdid(1, 114688, null, 0, i6.DrawerContentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DrawerContentComponentNgFactory = i1.ɵccf("app-drawer-content", i6.DrawerContentComponent, View_DrawerContentComponent_Host_0, {}, {}, []);
export { DrawerContentComponentNgFactory as DrawerContentComponentNgFactory };
