/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bar-saldo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/material/menu";
import * as i8 from "@angular/cdk/overlay";
import * as i9 from "@angular/cdk/bidi";
import * as i10 from "../../../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i11 from "./bar-saldo.component";
import * as i12 from "../../../services/home.service";
var styles_BarSaldoComponent = [i0.styles];
var RenderType_BarSaldoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BarSaldoComponent, data: {} });
export { RenderType_BarSaldoComponent as RenderType_BarSaldoComponent };
export function View_BarSaldoComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 16777216, null, null, 4, "button", [["aria-haspopup", "true"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-expanded", 0]], [[null, "mousedown"], [null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3)._handleMousedown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._handleClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(3, 1196032, null, 0, i7.MatMenuTrigger, [i8.Overlay, i1.ElementRef, i1.ViewContainerRef, i7.MAT_MENU_SCROLL_STRATEGY, [2, i7.MatMenu], [8, null], [2, i9.Directionality], i5.FocusMonitor], { menu: [0, "menu"] }, null), (_l()(), i1.ɵted(4, 0, ["Saldo: ", ""])), i1.ɵppd(5, 3), (_l()(), i1.ɵeld(6, 0, null, null, 13, "mat-menu", [["xPosition", "before"]], null, null, null, i10.View__MatMenu_0, i10.RenderType__MatMenu)), i1.ɵdid(7, 1294336, [["beforeMenu", 4]], 2, i7._MatMenu, [i1.ElementRef, i1.NgZone, i7.MAT_MENU_DEFAULT_OPTIONS], { xPosition: [0, "xPosition"] }, null), i1.ɵqud(603979776, 1, { items: 1 }), i1.ɵqud(603979776, 2, { lazyContent: 0 }), i1.ɵprd(2048, null, i7.MatMenu, null, [i7._MatMenu]), i1.ɵprd(2048, null, i7.MAT_MENU_PANEL, null, [i7.MatMenu]), (_l()(), i1.ɵeld(12, 0, null, 0, 3, "button", [["class", "mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 13)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i10.View_MatMenuItem_0, i10.RenderType_MatMenuItem)), i1.ɵdid(13, 180224, [[1, 4]], 0, i7.MatMenuItem, [i1.ElementRef, i2.DOCUMENT, i5.FocusMonitor, [2, i7.MAT_MENU_PANEL]], null, null), (_l()(), i1.ɵted(14, 0, ["Entrada: ", ""])), i1.ɵppd(15, 3), (_l()(), i1.ɵeld(16, 0, null, 0, 3, "button", [["class", "mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 17)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i10.View_MatMenuItem_0, i10.RenderType_MatMenuItem)), i1.ɵdid(17, 180224, [[1, 4]], 0, i7.MatMenuItem, [i1.ElementRef, i2.DOCUMENT, i5.FocusMonitor, [2, i7.MAT_MENU_PANEL]], null, null), (_l()(), i1.ɵted(18, 0, ["Salida: ", ""])), i1.ɵppd(19, 3)], function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 7); _ck(_v, 3, 0, currVal_3); var currVal_5 = "before"; _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); var currVal_2 = (i1.ɵnov(_v, 3).menuOpen || null); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), _co.saldo, "$ ", "symbol")); _ck(_v, 4, 0, currVal_4); var currVal_6 = i1.ɵnov(_v, 13).role; var currVal_7 = i1.ɵnov(_v, 13)._highlighted; var currVal_8 = i1.ɵnov(_v, 13)._triggersSubmenu; var currVal_9 = i1.ɵnov(_v, 13)._getTabIndex(); var currVal_10 = i1.ɵnov(_v, 13).disabled.toString(); var currVal_11 = (i1.ɵnov(_v, 13).disabled || null); _ck(_v, 12, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_12 = i1.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i1.ɵnov(_v, 0), _co.entrada, "$ ", "symbol")); _ck(_v, 14, 0, currVal_12); var currVal_13 = i1.ɵnov(_v, 17).role; var currVal_14 = i1.ɵnov(_v, 17)._highlighted; var currVal_15 = i1.ɵnov(_v, 17)._triggersSubmenu; var currVal_16 = i1.ɵnov(_v, 17)._getTabIndex(); var currVal_17 = i1.ɵnov(_v, 17).disabled.toString(); var currVal_18 = (i1.ɵnov(_v, 17).disabled || null); _ck(_v, 16, 0, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18); var currVal_19 = i1.ɵunv(_v, 18, 0, _ck(_v, 19, 0, i1.ɵnov(_v, 0), _co.salida, "$ ", "symbol")); _ck(_v, 18, 0, currVal_19); }); }
export function View_BarSaldoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bar-saldo", [], null, null, null, View_BarSaldoComponent_0, RenderType_BarSaldoComponent)), i1.ɵdid(1, 114688, null, 0, i11.BarSaldoComponent, [i12.HomeService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BarSaldoComponentNgFactory = i1.ɵccf("app-bar-saldo", i11.BarSaldoComponent, View_BarSaldoComponent_Host_0, {}, {}, []);
export { BarSaldoComponentNgFactory as BarSaldoComponentNgFactory };
