import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { TituloService } from './services/titulo.service';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { Subscription, timer } from 'rxjs';
import { debug } from 'util';




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  logstatesub: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: TituloService,
    private auth: AuthService,
  ) { }



  ngOnInit() {


    this.watchloginState();
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    ).subscribe((event) => this.titleService.titulo(event['title']));



  }

  ngOnDestroy(): void {
    // if (this.logstatesub != null)
    //   this.logstatesub.unsubscribe();
  }

  watchloginState() {
    // this.auth.loginStatus.asObservable().subscribe(x => {
    //   if (!x) {
    //     debugger
    //     this.router.navigateByUrl("/login");

    //   }


    // })

    // console.log("wea subscripta");
    // this.logstatesub = timer(0, 1000).pipe(
    //   switchMap(_ => this.auth.loginStatus.asObservable())
    // ).subscribe(x => {
    //   console.log("onCambio");
    //   if (!x)
    //     this.router.navigateByUrl("/login");
    // })
  }


}
