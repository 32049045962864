import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { RequestClass } from './request.class';
import { map, catchError, tap } from 'rxjs/operators';
import { NotifService } from './notif.service';
import { ILoginCredentials } from '../classes/ilogin.credentials';
import { UsuarioEntity } from '../classes/usuario-entity';
import { ISession } from '../classes/isession';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  loginStatus = new BehaviorSubject<boolean>(false);


  constructor(private router: Router, private request: RequestClass, private notif: NotifService) {
    this.IsLoggedIn().subscribe(x => this.loginStatus.next(x));

  }

  isLocalstoreNotEmpty(): Observable<boolean> {
    return of(localStorage.length != 0);
  }

  IsLoggedIn(): Observable<boolean> {

    let expiration: string = localStorage.getItem('expiration');
    if (expiration != null && new Date(expiration) >= new Date())
      return of(true).pipe(
        map(x => {
          this.loginStatus.next(x);
          return x;
        })
      );
    else
      return this.request.ApiGet("/auth").pipe(
        map(x => true),
        catchError(x => of(false)),
        map(x => {
          this.loginStatus.next(x);
          return x;
        })
      );
  }

  doLogin(login: ILoginCredentials): Observable<any> {
    login = { ...login, ...{ web: true } };
    return this.request.ApiPost_Public<any>(`auth/login`, login).pipe(
      tap(x => {
        if (!x.res)
          throw Error(x.err);

        this.setToken(x.data.session);
        this.setSessionInfo(x.data.user as UsuarioEntity);
        this.setVariables(x.data.variables);
        this.loginStatus.next(true);
        this.notif.success("La sesión se ha iniciado correctamente", "Fértil Suma", 3000);
        this.router.navigateByUrl("/inicio");


      }, error => {
        this.loginStatus.next(false);
        console.log("LoginError:", error)
        if (error.status == 401) {
          this.notif.warning("Los datos ingresados son inválidos.", "Error", 3000, false, false, "toast-top-center")
        }
        else {
          this.notif.error("Se produjo un error inesperado al intentar iniciar la sesión.", "Error", 3000, false, false, "toast-top-center")
        }
      }
      ));

  }

  doLogout() {

    this.loginStatus.next(false);

    localStorage.clear();
    this.router.navigateByUrl("/login");
    return of();
  }

  getToken() {
    return localStorage.getItem('token');
  }
  setToken(session: ISession) {
    localStorage.setItem('token', session.token);
    localStorage.setItem('expiration', session.expiration);
  }
  setVariables(variables: any) {
    localStorage.setItem('variables', variables);
  }
  getSessionInfo() {

    let userData = localStorage.getItem("sessionInfo");
    if (!userData)
      return null;

    return JSON.parse(userData);
  }
  setSessionInfo(userData: UsuarioEntity) {
    localStorage.setItem("sessionInfo", JSON.stringify(userData))
  }

  SaveRecoverPass(k, password): Observable<any> {

    let accountData = {
      key: k,
      password: password
    };

    return this.request.ApiPost_Public("/auth/update_password", accountData).pipe(catchError(e => {
      return of({ res: false, err: "Se produjo un error inesperado." });
    }));
  }

  RecoverAcount(k, dni): Observable<any> {

    let accountData = {
      email: k,
      dni: dni
    };

    return this.request.ApiPost_Public("/auth/recover_password", accountData).pipe(catchError(e => {
      return of({ res: false, err: "Se produjo un error inesperado." });
    }));
  }

  ActivateAccount(k): Observable<any> {


    return this.request.ApiGet_Public("/auth/validate/" + k).pipe(catchError(e => {
      return of({ res: false, err: "Se produjo un error inesperado." });
    }));
  }

  RegretBaja(k: string): Observable<any> {

    debugger;
    return this.request.ApiPost_Public("/baja-cuenta/user-arrepentimiento/", { regretCode: k }).pipe(catchError(e => {
      return of({ res: false, err: "Se produjo un error inesperado." });
    }));
  }

  SendDni(k): Observable<any> {

    debugger;
    return this.request.ApiGet_Public("auth/sissa/" + k.dni + "/" + k.sexo).pipe(catchError(e => {
      return of({ res: false, err: "Se produjo un error inesperado." });
    }));
  }

  GetLocalidades(): Observable<any> {


    return this.request.ApiGet_Public<any>("localidades/localidades").pipe(
      catchError(e => {
        return of({ res: false, err: "Se produjo un error inesperado." });
      }));
  }

  RegisterAccount(accountData: any): Observable<any> {

    return this.request.ApiPost_Public("/auth/signup", accountData).pipe(catchError(e => {
      return of({ res: false, err: "Se produjo un error inesperado." });
    }));
  }

  SendCuit(k): Observable<any> {


    return this.request.ApiGet_Public("auth/signup_pj/get_persona/" + k).pipe(catchError(e => {
      return of({ res: false, err: "Se produjo un error inesperado." });
    }));
  }


  VerifyEmailAndPhoneAndSendCode(k): Observable<any> {



    return this.request.ApiPost_Public("auth/signup_pj/submit_1", k).pipe(catchError(e => {
      return of({ res: false, err: "Se produjo un error inesperado." });
    }));
  }

  SignupEmpresa(k): Observable<any> {



    return this.request.ApiPost_Public("auth/signup_pj/submit_2", k).pipe(catchError(e => {
      return of({ res: false, err: "Se produjo un error inesperado." });
    }));
  }

}
