/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/components/slide-show/slide-show.component.ngfactory";
import * as i3 from "../../shared/components/slide-show/slide-show.component";
import * as i4 from "../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i5 from "@angular/material/card";
import * as i6 from "@angular/forms";
import * as i7 from "../../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i8 from "@angular/material/menu";
import * as i9 from "@angular/common";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "./login.component";
import * as i12 from "@angular/material/dialog";
import * as i13 from "@angular/router";
import * as i14 from "../../services/auth.service";
var styles_LoginComponent = [i0.styles];
var RenderType_LoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
export function View_LoginComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 33, "div", [["id", "publicContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "slideshowContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-slide-show", [], null, null, null, i2.View_SlideShowComponent_0, i2.RenderType_SlideShowComponent)), i1.ɵdid(3, 114688, null, 0, i3.SlideShowComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 29, "div", [["class", "formContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 28, "mat-card", [["class", "mat-card"]], null, null, null, i4.View_MatCard_0, i4.RenderType_MatCard)), i1.ɵdid(6, 49152, null, 0, i5.MatCard, [], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 26, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.onSubmit() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 16384, null, 0, i6.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(9, 540672, null, 0, i6.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, { ngSubmit: "ngSubmit" }), i1.ɵprd(2048, null, i6.ControlContainer, null, [i6.FormGroupDirective]), i1.ɵdid(11, 16384, null, 0, i6.NgControlStatusGroup, [[4, i6.ControlContainer]], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 9, "mat-card-header", [["class", "mat-card-header"]], null, null, null, i4.View_MatCardHeader_0, i4.RenderType_MatCardHeader)), i1.ɵdid(13, 49152, null, 0, i5.MatCardHeader, [], null, null), (_l()(), i1.ɵeld(14, 0, null, 2, 7, "mat-card-title-group", [["class", "mat-card-title-group"]], null, null, null, i4.View_MatCardTitleGroup_0, i4.RenderType_MatCardTitleGroup)), i1.ɵdid(15, 49152, null, 0, i5.MatCardTitleGroup, [], null, null), (_l()(), i1.ɵeld(16, 0, null, 0, 3, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(17, 16384, null, 0, i5.MatCardTitle, [], null, null), (_l()(), i1.ɵeld(18, 0, null, null, 1, "img", [["class", "mat-card-image"], ["mat-card-image", ""], ["src", "/assets/LogoWallet.png"]], null, null, null, null, null)), i1.ɵdid(19, 16384, null, 0, i5.MatCardImage, [], null, null), (_l()(), i1.ɵeld(20, 0, null, 0, 1, "mat-card-subtitle", [["class", "mat-card-subtitle"]], null, null, null, null, null)), i1.ɵdid(21, 16384, null, 0, i5.MatCardSubtitle, [], null, null), (_l()(), i1.ɵeld(22, 0, null, null, 1, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(23, 16384, null, 0, i5.MatCardContent, [], null, null), (_l()(), i1.ɵeld(24, 0, null, null, 3, "div", [["class", "g-play"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotoPlayStore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 1, "span", [["color", "primary"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Descarga la App en"])), (_l()(), i1.ɵeld(27, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/gplay.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 5, "mat-card-actions", [["class", "mat-card-actions"]], [[2, "mat-card-actions-align-end", null]], null, null, null, null)), i1.ɵdid(29, 16384, null, 0, i5.MatCardActions, [], null, null), (_l()(), i1.ɵeld(30, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 2, "a", [["class", "text-center mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 32)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 32)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.navigateToRecoverPass() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i7.View_MatMenuItem_0, i7.RenderType_MatMenuItem)), i1.ɵdid(32, 180224, null, 0, i8.MatMenuItem, [i1.ElementRef, i9.DOCUMENT, i10.FocusMonitor, [2, i8.MAT_MENU_PANEL]], null, null), (_l()(), i1.ɵted(-1, 0, ["Olvid\u00E9 mi contrase\u00F1a"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_7 = _co.formulario; _ck(_v, 9, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 11).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 11).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 11).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 11).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 11).ngClassValid; var currVal_5 = i1.ɵnov(_v, 11).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 11).ngClassPending; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = (i1.ɵnov(_v, 29).align === "end"); _ck(_v, 28, 0, currVal_8); var currVal_9 = i1.ɵnov(_v, 32).role; var currVal_10 = i1.ɵnov(_v, 32)._highlighted; var currVal_11 = i1.ɵnov(_v, 32)._triggersSubmenu; var currVal_12 = i1.ɵnov(_v, 32)._getTabIndex(); var currVal_13 = i1.ɵnov(_v, 32).disabled.toString(); var currVal_14 = (i1.ɵnov(_v, 32).disabled || null); _ck(_v, 31, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); }); }
export function View_LoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i1.ɵdid(1, 114688, null, 0, i11.LoginComponent, [i12.MatDialog, i13.Router, i14.AuthService, i13.Router, i6.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginComponentNgFactory = i1.ɵccf("app-login", i11.LoginComponent, View_LoginComponent_Host_0, {}, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
