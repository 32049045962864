import { Injectable } from '@angular/core';
import { Breakpoints, BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotifService {

  positionClass:string = 'toast-top-right';

  constructor(
    private toast: ToastrService,
    private breakpointObserver: BreakpointObserver
  ) { 
    this.breakpointObserver
    .observe([Breakpoints.HandsetLandscape,Breakpoints.Handset])
    .subscribe((state: BreakpointState) => {
      this.positionClass = 'toast-top-right';
      if(state.matches){
        this.positionClass = 'toast-bottom-center';
      }
      
    });
  }

  success(msg:string, title:string, duration:number = 2000, showClose: boolean = true, showLoading: boolean = true, positionClass: string = this.positionClass){
    this.toast.success(msg, title, {
      timeOut: duration,
      closeButton: showClose,
      progressBar: showLoading,
      progressAnimation: 'increasing',
      positionClass: positionClass
    })
  }
  error(msg:string, title:string, duration:number = 2000, showClose: boolean = true, showLoading: boolean = true, positionClass: string = this.positionClass){
    this.toast.error(msg, title, {
      timeOut: duration,
      closeButton: showClose,
      progressBar: showLoading,
      progressAnimation: 'increasing',
      positionClass: positionClass
    })
  }
  warning(msg:string, title:string, duration:number = 2000, showClose: boolean = true, showLoading: boolean = true, positionClass: string = this.positionClass){
    this.toast.warning(msg, title, {
      timeOut: duration,
      closeButton: showClose,
      progressBar: showLoading,
      progressAnimation: 'increasing',
      positionClass: positionClass
    })
  }
  info(msg:string, title:string, duration:number = 2000, showClose: boolean = true, showLoading: boolean = true, positionClass: string = this.positionClass){
    this.toast.info(msg, title, {
      timeOut: duration,
      closeButton: showClose,
      progressBar: showLoading,
      progressAnimation: 'increasing',
      positionClass: positionClass
    })
  }
}
