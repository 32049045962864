import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule, MatFormFieldModule, MatInputModule, MatDialogModule, MatProgressSpinnerModule, MatStepperModule, MatSelectModule, MatOptionModule, MatListModule, MatExpansionModule, MatRippleModule, MatTableModule, MatProgressBarModule, MatRadioButton, MatRadioModule, MatButtonToggleModule, MatAutocompleteModule, MatGridTile, MatGridListModule, MatCheckboxModule, MatSlideToggleModule } from '@angular/material';
import { DialogoAlertComponent } from './components/dialogo-alert/dialogo-alert.component';
import { DialogoConfirmacionComponent } from './components/dialogo-confirmacion/dialogo-confirmacion.component';

const ExportModules = [
  MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule,
  MatMenuModule, MatCardModule, MatFormFieldModule, MatInputModule, MatDialogModule, MatProgressSpinnerModule, MatStepperModule, MatSelectModule, MatOptionModule, MatListModule, MatExpansionModule,
  MatRippleModule, MatTableModule, MatProgressBarModule, MatRadioModule, MatButtonToggleModule,
  MatAutocompleteModule, MatGridListModule, MatCheckboxModule, MatSlideToggleModule
]



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ExportModules,
  ],
  exports: [
    ExportModules,
  ],
  entryComponents: [DialogoAlertComponent, DialogoConfirmacionComponent]
})
export class MaterialModule { }
