/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dialogo-confirmacion.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./dialogo-confirmacion.component";
var styles_DialogoConfirmacionComponent = [i0.styles];
var RenderType_DialogoConfirmacionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DialogoConfirmacionComponent, data: {} });
export { RenderType_DialogoConfirmacionComponent as RenderType_DialogoConfirmacionComponent };
export function View_DialogoConfirmacionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "dialog-title mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(2, null, [" ", "\n"])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "dialog-content mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "dialog-actions text-center d-flex mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["color", "primary"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onConfirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(11, 0, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(13, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(14, 0, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_5 = "primary"; _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.message; _ck(_v, 6, 0, currVal_2); var currVal_3 = (i1.ɵnov(_v, 10).disabled || null); var currVal_4 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_3, currVal_4); var currVal_6 = _co.confirmbtn; _ck(_v, 11, 0, currVal_6); var currVal_7 = (i1.ɵnov(_v, 13).disabled || null); var currVal_8 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_7, currVal_8); var currVal_9 = _co.cancelbtn; _ck(_v, 14, 0, currVal_9); }); }
export function View_DialogoConfirmacionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialogo-confirmacion", [], null, null, null, View_DialogoConfirmacionComponent_0, RenderType_DialogoConfirmacionComponent)), i1.ɵdid(1, 114688, null, 0, i7.DialogoConfirmacionComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DialogoConfirmacionComponentNgFactory = i1.ɵccf("app-dialogo-confirmacion", i7.DialogoConfirmacionComponent, View_DialogoConfirmacionComponent_Host_0, {}, {}, []);
export { DialogoConfirmacionComponentNgFactory as DialogoConfirmacionComponentNgFactory };
