import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MovimientosService } from '../../movimientos.service';
import { switchMap, map } from 'rxjs/operators';
import { IMovimientos } from 'src/app/classes/imovimientos';


@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.scss']
})
export class DetalleComponent implements OnInit {

  constructor(private ar: ActivatedRoute, private service: MovimientosService) { }

  id: number;
  data: IMovimientos;


  ngOnInit() {
    this.ar.params.pipe(
      map(x => {

        this.id = x["id"];
        return this.id;
      }),
      switchMap(x => this.service.GetEntityById(x))
    ).subscribe(x => {

      this.data = x.data;

    });

  }


}
