import { Component, OnInit } from '@angular/core';
import { TituloService } from 'src/app/services/titulo.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  constructor(private title: TituloService) { }
  private _titulo = `Fértil Suma`;
  Title: string = ``;


  ngOnInit() {
    this.title.getTitulo().subscribe(x => x != "" ? this.Title = `${x}` : "");


  }

}
