<!-- <mat-card class="clickableIngreso">
    <fieldset>
        <legend class="miniCardTitle">Dinero Pendiente de ingresar</legend>
        <span class="innerElementMiniCard">{{pendienteEntrada | currency:"$ ":"symbol"}}</span>
    </fieldset>
</mat-card> -->
<mat-card class="clickableIngreso">
    <mat-card-title class="miniCardTitle"> Dinero Pendiente de Ingresar</mat-card-title>
    <div class="pendientes">{{pendienteEntrada | currency:"$ ":"symbol"}}</div>
    <!-- <mat-card-title class="innerElementMiniCard"> </mat-card-title> -->
    <!-- <fieldset>
        <legend class="miniCardTitle">Dinero Pendiente de Ingresar</legend>
        <legend class="innerElementMiniCard">{{pendienteSalida | currency:"$ ":"symbol"}} </legend>
    </fieldset> -->
</mat-card>