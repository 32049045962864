import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class TituloService {
    constructor() {
        this.titulo$ = new BehaviorSubject("");
    }
    getTitulo() {
        return this.titulo$.asObservable();
    }
    titulo(value) {
        this.titulo$.next(value);
    }
}
TituloService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TituloService_Factory() { return new TituloService(); }, token: TituloService, providedIn: "root" });
