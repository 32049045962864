import { Component, OnInit } from '@angular/core';
import { iMenuItem } from 'src/app/classes/imenu-item';

@Component({
  selector: 'app-drawer-content',
  templateUrl: './drawer-content.component.html',
  styleUrls: ['./drawer-content.component.scss']
})
export class DrawerContentComponent implements OnInit {

  items: iMenuItem[] = [{ icon: "assets/inicio.svg", label: "Inicio", path: "inicio", children: [], expanded: false },
  { icon: "assets/movimientos.svg", label: "Movimientos", path: "movimientos", children: [], expanded: false, },];

  constructor() { }

  ngOnInit() {

    let tipo_usuario = JSON.parse(localStorage.getItem("sessionInfo")).tipo;


    if (tipo_usuario == 2 || tipo_usuario == 1)
      this.items.push(
        {
          icon: "assets/cobrar.svg", label: "Cobrar", path: "", children: [
            { icon: "assets/cobro-qr.svg", label: "Cobrar con QR", path: "cobrar/qr", children: [], expanded: false },
            { icon: "assets/cobrar-link-pago.svg", label: "Cobrar con Link de pago", path: "cobrar/link", children: [], expanded: false }
          ], expanded: false
        });


    if (tipo_usuario == 1)
      this.items.push(
        {
          icon: "assets/retirar.svg", label: "Retirar", path: "", children: [
            { icon: "assets/enviar-dinero.svg", label: "Enviar Dinero", path: "retirar/enviar-dinero", children: [], expanded: false },
            { icon: "assets/retiro-sucursal.svg", label: "Retiro en Sucursal", path: "retirar/retiro-sucursal", children: [], expanded: false },
            { icon: "", label: "Transferencia con CVU", path: "retirar/transferencia-CVU", children: [], expanded: false }
          ], expanded: false
        },
        {
          icon: "assets/recargas.svg", label: "Recargas", path: "", children: [
            { icon: "assets/recargar-celular.svg", label: "Recarga Celular", path: "recargas/celular", children: [], expanded: false },
            { icon: "assets/recarga-sube.svg", label: "Recarga SUBE", path: "recargas/sube", children: [], expanded: false }
          ], expanded: false
        }, {
        icon: "assets/ingresar.svg", label: "Ingresar", path: "", children: [
          { icon: "", label: "Prestamo Fértil", path: "ingresar/prestamo", children: [], expanded: false }
        ], expanded: false
      },

        { icon: "assets/configuracion.svg", label: "Configuración", path: "configuracion", children: [], expanded: false }
      );

  }

  toggle(item) {

    item.expand = !item.expand;
  }

}
