import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';



export class ConfirmDialogModel {
  constructor(
    public title: string,
    public message: string,
    public confirmbtn: string = "Confirmar",
    public cancelbtn: string = "Cancelar",
  ) {
  }
}


@Component({
  selector: 'app-dialogo-confirmacion',
  templateUrl: './dialogo-confirmacion.component.html',
  styleUrls: ['./dialogo-confirmacion.component.scss']
})
export class DialogoConfirmacionComponent implements OnInit {

  title: string;
  message: string;
  public confirmbtn: string;
  public cancelbtn: string;

  constructor(public dialogRef: MatDialogRef<DialogoConfirmacionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.confirmbtn = data.confirmbtn;
    this.cancelbtn = data.cancelbtn;
  }

  ngOnInit() {
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

}
