<!-- <ng-image-slider class="slidernani" [images]="imageObject" [imageSize]="{width: 820,height:653,space: 0}"
    [infinite]=true [autoSlide]=3 [showArrow]=false #nav [imagePopup]=false></ng-image-slider> -->

<div class="carrouselContainer">
    <mat-carousel timings="250ms ease-in" [autoplay]="true" interval="3000" color="primary" maxWidth="auto" slides="3"
        [loop]="true" [hideArrows]="false" [hideIndicators]="false" [useKeyboard]="true" [useMouseWheel]="false"
        orientation="ltr" slideHeight="100%" maintainAspectRatio="false">


        <mat-carousel-slide #matCarouselSlide *ngFor="let slide of imageObject; let i = index" [image]="slide.image"
            [hideOverlay]="true"></mat-carousel-slide>
    </mat-carousel>
    <!-- <ng-image-slider class="slidernani" [images]="imageObject" [imageSize]="{width: 1500,height:777,space: 0}"
        [infinite]=true [autoSlide]=3 [showArrow]=false #nav [imagePopup]=false></ng-image-slider> -->
    <!-- <mat-carousel timings="250ms ease-in" [autoplay]="true" interval="4000" color="accent" maxWidth="auto"
        slideHeight="200px" proportion="100" slides="3" [loop]="true" maintainAspectRatio="false" [hideArrows]="false"
        [hideIndicators]="true" [useKeyboard]="false" [useMouseWheel]="false" orientation="ltr">
        <mat-carousel-slide #matCarouselSlide image="assets/ubuntu1.jpg" overlayColor="#00000010" [hideOverlay]="false">
        </mat-carousel-slide>
        <mat-carousel-slide #matCarouselSlide image="assets/ubuntu2.jpg" [hideOverlay]="false">
        </mat-carousel-slide>
        <mat-carousel-slide #matCarouselSlide image="assets/ubuntu3.jpg" [hideOverlay]="false">
        </mat-carousel-slide>

    </mat-carousel> -->
    <!-- <ul class="carousel-inner" #carousel>
    
        <li>1</li>
        <li>2</li>
        <li>3</li>
    </ul> -->
</div>