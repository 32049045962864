<div id="publicContainer">
	<div class="slideshowContainer">
		<app-slide-show></app-slide-show>
	</div>
	<div class="formContainer">
		<mat-card>
			<form novalidate (ngSubmit)="onSubmit()" [formGroup]="formulario">


				<mat-card-header>

					<mat-card-title-group>
						<mat-card-title><img mat-card-image src="/assets/LogoWallet.png"></mat-card-title>
						<mat-card-subtitle>Recupero de Contraseña</mat-card-subtitle>
					</mat-card-title-group>
				</mat-card-header>

				<mat-card-content>


					<div>
						<mat-form-field appearance="outline">
							<mat-label>
								<mat-icon matSuffix>email</mat-icon> Email
							</mat-label>
							<input type="email" matInput formControlName="login" required>

						</mat-form-field>
						<mat-error
							*ngIf="submitted && formulario.controls.login.errors?.email && !formulario.controls.login.errors?.required">
							<small>Email invalido</small> </mat-error>
						<mat-error *ngIf="submitted && formulario.controls.login.errors?.required"><small>El email es
								obligatorio</small></mat-error>
					</div>

					<div>
						<mat-form-field appearance="outline">
							<mat-label>
								<mat-icon matSuffix>credit_card</mat-icon> DNI
							</mat-label>
							<input type="text" matInput formControlName="dni" required>
						</mat-form-field>
						<mat-error *ngIf="submitted && formulario.controls.dni.errors?.required">
							<small>El DNI es obligatorio</small></mat-error>
					</div>
				</mat-card-content>
				<mat-card-actions>
					<button mat-raised-button color="primary" (click)=navigateToLogin() *ngIf="!IsLoading"
						type="button">Atrás</button>
					<button mat-raised-button color="primary" type="submit" *ngIf="!IsLoading">Recuperar</button>
					<div class="loading" *ngIf="IsLoading">
						<mat-spinner diameter="30"></mat-spinner>
					</div>
				</mat-card-actions>
			</form>
		</mat-card>
	</div>
</div>