import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export class AlertDialogModel {
  constructor(
      public title: string,
      public message: string,
      public dismissBtn: string = "Ok",
    ) {
  }
}


@Component({
  selector: 'app-dialogo-alert',
  templateUrl: './dialogo-alert.component.html',
  styleUrls: ['./dialogo-alert.component.scss']
})
export class DialogoAlertComponent implements OnInit {

  title: string;
  message: string;
  public dismissBtn: string;


  constructor(public dialogRef: MatDialogRef<DialogoAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogModel) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.dismissBtn = data.dismissBtn;

  }

  ngOnInit() {
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }


}
