import { Injectable } from '@angular/core';
import { RequestClass } from 'src/app/services/request.class';
import { catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MovimientosService {

  constructor(private request: RequestClass) { }

  GetMovimientos(periodo: string, tipo: string, movimiento: string, estado: string, colaborador: string): Observable<any> {
    return this.request.ApiGet(`/movimientos/${periodo}/${tipo}/${movimiento}/${estado}/${colaborador}`).pipe(catchError(e => {
      return of({ res: false, err: "Se Produjo un error inesperado." });
    }));
  }

  GetEntityById(id: number = 0): Observable<any> {
    return this.request.ApiGet(`/movimientos/${id}`).pipe(catchError(e => {
      return of({ res: false, err: "Se Produjo un error inesperado." });
    }));
  }

  Cancelar(codMov: number, numMov: number): Observable<any> {
    return this.request.ApiDelete(`/movimientos/${codMov}/${numMov}`).pipe(catchError(e => {
      return of({ res: false, err: "Se Produjo un error inesperado." });
    }));
  }


  GetColaboradores(): Observable<any> {
    return this.request.ApiGet(`/comercios/colaboradores`).pipe(catchError(e => {
      return of({ res: false, err: "Se Produjo un error inesperado." });
    }));
  }
}
