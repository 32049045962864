import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { TituloService } from 'src/app/services/titulo.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private title: TituloService, private auth: AuthService, private router: Router) { }

  ngOnInit() {

  }

  DoLogout() {
    // this.auth.doLogout().subscribe(x => {

    // });
  }
}
