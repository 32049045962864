<div class="d-flex w-100">
    <div class="icon ">
        <img src="{{icon}}" class="{{IconColor}}">
    </div>
    <div class="w-100">
        <div class="concepto">
            {{label}}
        </div>
        <div class="estado" [style.color]=color>
            {{estado}}
        </div>

    </div>
    <div>
        <div class="text-nowrap importe text-right">
            {{movi.IMPORTE | currency:"$ ":"symbol"}}
        </div>
        <div class="text-nowrap fecha  text-right">
            {{movi.FECHA | date:'longDate'}}
        </div>
    </div>
</div>