<div id="publicContainer">
    <div class="slideshowContainer">
        <app-slide-show></app-slide-show>
    </div>
    <div class="formContainer">
        <mat-card class="mat-elevation-z0">

            <ng-container [ngSwitch]="screen">
                <ng-container *ngSwitchDefault>



                    <mat-card-header>

                        <mat-card-title>
                            <mat-spinner></mat-spinner>
                        </mat-card-title>
                    </mat-card-header>

                    <mat-card-content>

                        <b>Cargando...</b>

                    </mat-card-content>



                </ng-container>
                <ng-container *ngSwitchCase="1">

                    <img class="img-msg" src="assets/exito.png">
                    <mat-card-header>


                        <mat-card-title><b>Tu cuenta se reactivó correctamente.</b></mat-card-title>
                    </mat-card-header>

                    <mat-card-actions>
                        <button mat-raised-button color="primary" type="button" (click)="navigateToLogin()">Iniciar
                            sesión</button>

                    </mat-card-actions>


                </ng-container>
                <ng-container *ngSwitchCase="3">

                    <img class="img-msg" src="assets/error.png">
                    <mat-card-header>


                        <mat-card-title><b>No hemos podido reactivar tu cuenta</b></mat-card-title>
                    </mat-card-header>

                    <mat-card-content>

                        El link de reactivación vía email expiró, intentá iniciar sesión y podrás solicitar uno nuevo.

                    </mat-card-content>

                    <mat-card-actions>
                        <button mat-raised-button color="primary" type="button" (click)="navigateToLogin()">Ir a
                            inicio</button>

                    </mat-card-actions>


                </ng-container>
                <ng-container *ngSwitchCase="4">

                    <img class="img-msg" src="assets/error.png">
                    <mat-card-header>


                        <mat-card-title><b>No hemos podido reactivar tu cuenta</b></mat-card-title>
                    </mat-card-header>

                    <mat-card-content>

                        Intentelo más tarde.

                    </mat-card-content>

                    <mat-card-actions>
                        <button mat-raised-button color="primary" type="button" (click)="navigateToLogin()">Ir a
                            Inicio</button>

                    </mat-card-actions>


                </ng-container>

                <ng-container *ngSwitchCase="2">

                    <img class="img-msg" src="assets/error.png">
                    <mat-card-header>


                        <mat-card-title><b>No hemos podido reactivar tu cuenta</b></mat-card-title>
                    </mat-card-header>

                    <mat-card-content>

                        Intentelo más tarde.

                    </mat-card-content>

                    <mat-card-actions>
                        <button mat-raised-button color="primary" type="button" (click)="navigateToLogin()">Ir a
                            Inicio</button>

                    </mat-card-actions>


                </ng-container>
            </ng-container>
        </mat-card>
    </div>
</div>