<div class="wrapper">

  <!-- PANTALLA EN DESARROLLO -->

  <div class="saldo">
    <app-saldo></app-saldo>

  </div>

  <div class="entrada">
    <app-saldo-entrada class="saldosAbajo"></app-saldo-entrada>

  </div>
  <div class="salida">
    <app-saldo-salida class="saldosAbajo"></app-saldo-salida>
  </div>

  <div class="movimientos">
    <!-- OEEEEE -->
    <app-top-movimientos></app-top-movimientos>
  </div>
  <div class="image">
    <img src="assets/ubuntu1.jpg" alt="">
  </div>




</div>