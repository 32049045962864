

export interface IMovimientos {
    ID: number;
    FECHA: Date;
    FECHA_RETIRO: Date;
    COD_MOV: number;
    NUM_MOV: number
    CONCEPTO: string;
    IMPORTE: number;
    CANCELABLE_N: number;
    CARGOS: number;
    CANCELABLE: boolean;
    TIPO: number;
    ID_ESTADO: number;
    MOTIVO: string;
    ID_MEDIO_PAGO: number;
}
export class TipoMovimientoFilter {

    opciones = [
        { value: 0, nombre: "Todas" },
        { value: 10, nombre: "Carga de saldo en billetera", icon: "" },
        { value: 20, nombre: "Envío a billetera", icon: "assets/enviar-dinero.svg" },
        { value: 22, nombre: "Cobro/Pago por QR", icon: "" },
        { value: 24, nombre: "Retiro en sucursal", icon: "assets/retiro-sucursal.svg" },//"Reserva para retiro por sucursal"

        { value: 30, nombre: "Transferencia por CVU", icon: "assets/transferencia-cvu.svg" },
        { value: 32, nombre: "Recarga Telefono", icon: "assets/recargar-celular.svg" },
        { value: 33, nombre: "Recarga Tarjeta SUBE", icon: "assets/recarga-sube.svg" },
        { value: 38, nombre: "Recarga con préstamo Fértil", icon: "assets/ingresar.svg" },
        { value: 40, nombre: "Cobro/Pago con link de Pago", icon: "assets/cobrar-link-pago.svg" },
    ];

    getLabel(value, importe) {
        var res = this.opciones.find(x => x.value == value);
        if (value == 22 && importe < 0)
            return "Pago por QR";
        else if (value == 22 && importe > 0)
            return "Cobro por QR";
        if (value == 40 && importe < 0)
            return "Pago por Link";
        else if (value == 40 && importe > 0)
            return "Cobro por Link";
        else
            return res.nombre;
    }

    getIcon(value, importe) {

        var res = this.opciones.find(x => x.value == value);
        if (value == 22 && importe < 0)
            return "assets/pago-qr.svg";
        else if (value == 22 && importe > 0)
            return "assets/cobro-qr.svg";
        else
            return res.icon == "" ? "assets/generic-icon.svg" : res.icon;
    }
}

export class EstadosFilter {

    opciones = [
        { value: 0, nombre: "Todos" },
        { value: 1, nombre: "Completa", color: "#2e7d32" },
        { value: 2, nombre: "Pendiente", color: "#9e9d24" },
        { value: 3, nombre: "Cancelada", color: "#c62828" }
    ];

    getEstado(value) {
        var res = this.opciones.find(x => x.value == value);
        return res.nombre;
    }
    getColor(value) {
        var res = this.opciones.find(x => x.value == value);
        return res.color;
    }
}


export class PeriodoFilter {

    opciones = [
        { value: 0, nombre: "Hoy" },
        { value: 1, nombre: "Ayer" },
        { value: 2, nombre: "Última semana" },
        { value: 3, nombre: "Últimos 15 días" },
        { value: 4, nombre: "Último Mes" },
        { value: 5, nombre: "Último 3 Meses" },
        { value: 6, nombre: "Último Año" },
        { value: 7, nombre: "Ultimos movimientos" }
    ];

    getPeriodo(value) {
        var res = this.opciones.find(x => x.value == value);
        return res.nombre;
    }
}

export class TipoFilter {

    opciones = [
        { value: 0, nombre: "Todos" },
        { value: 1, nombre: "Solo Ingresos" },
        { value: 2, nombre: "Solo Egresos" }
    ];

    getTipo(value) {
        var res = this.opciones.find(x => x.value == value);
        return res.nombre;
    }
    getColor(importe) {
        if (importe > 0)
            return "green";
        else if (importe < 0)
            return "red";
        else return "";

    }
}



