/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../saldo/saldo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i4 from "@angular/material/card";
import * as i5 from "./saldo-salida.component";
import * as i6 from "../../../../services/home.service";
var styles_SaldoSalidaComponent = [i0.styles];
var RenderType_SaldoSalidaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SaldoSalidaComponent, data: {} });
export { RenderType_SaldoSalidaComponent as RenderType_SaldoSalidaComponent };
export function View_SaldoSalidaComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 7, "mat-card", [["class", "clickableIngreso mat-card"]], null, null, null, i3.View_MatCard_0, i3.RenderType_MatCard)), i1.ɵdid(2, 49152, null, 0, i4.MatCard, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "mat-card-title", [["class", "miniCardTitle mat-card-title"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i4.MatCardTitle, [], null, null), (_l()(), i1.ɵted(-1, null, [" Dinero Pendiente de Salir"])), (_l()(), i1.ɵeld(6, 0, null, 0, 2, "div", [["class", "pendientes"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵppd(8, 3)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 0), _co.pendienteSalida, "$ ", "symbol")); _ck(_v, 7, 0, currVal_0); }); }
export function View_SaldoSalidaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-saldo-salida", [], null, null, null, View_SaldoSalidaComponent_0, RenderType_SaldoSalidaComponent)), i1.ɵdid(1, 245760, null, 0, i5.SaldoSalidaComponent, [i6.HomeService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SaldoSalidaComponentNgFactory = i1.ɵccf("app-saldo-salida", i5.SaldoSalidaComponent, View_SaldoSalidaComponent_Host_0, {}, {}, []);
export { SaldoSalidaComponentNgFactory as SaldoSalidaComponentNgFactory };
