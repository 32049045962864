import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { NotifService } from 'src/app/services/notif.service';


enum ActivateScreen {
  loading, success, bad_account_state_error, key_expired_error, unknown_error
}



@Component({
  selector: 'app-regret-baja',
  templateUrl: './regret-baja.component.html',
  styleUrls: ['./regret-baja.component.scss']
})
export class RegretBajaComponent implements OnInit {



  k: string = "";
  screen: ActivateScreen = ActivateScreen.loading;

  constructor(
    private router: Router, private ar: ActivatedRoute,
    private auth: AuthService, private notif: NotifService, private breakpointObserver: BreakpointObserver) { }


  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  navigateToLogin() {
    this.router.navigateByUrl('/login');
  }


  ngOnInit() {
    this.ar.params.subscribe(x => {
      this.k = x["k"];
      this.auth.RegretBaja(this.k).subscribe(x => {
        debugger;
        if (!x.res) {
          switch (x.err) {
            case "account_state_invalid":
              this.screen = ActivateScreen.bad_account_state_error;
              break;
            case "code_invalid_or_expired":
              this.screen = ActivateScreen.key_expired_error;
              break;
            default:
              this.screen = ActivateScreen.unknown_error;
              break;
          }
        }
        else {
          this.screen = ActivateScreen.success;
        }
      });
    })
  }


}
