import { Component, OnInit, Input } from '@angular/core';
import { iMenuItem } from 'src/app/classes/imenu-item';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {

  @Input() item: iMenuItem;
  @Input() isLast: boolean; //si es el ultimo hijo, no deberia tener un separador.
  @Input() isChild: boolean;


  constructor(private router: Router, private ar: ActivatedRoute) { }

  ngOnInit() {
  }

  toggle() {
    this.item.expanded = !this.item.expanded;
  }
  collapse() {
    
    this.item.expanded = false;
  }

  onTap() {
    if (this.item.children.length > 0) {
      this.toggle();
    }
    else {
      this.router.navigateByUrl(this.item.path);
    }
  }

  activo() {

    return this.item.path != "" && "/" + this.item.path == this.router.url
  }

}
