import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-top-movimientos',
  templateUrl: './top-movimientos.component.html',
  styleUrls: ['./top-movimientos.component.scss']
})
export class TopMovimientosComponent implements OnInit {

  displayedColumns = ['position'];
  dataSource: any;

  constructor(private service: HomeService) { }

  ngOnInit() {
    this.service.GetMovimientosTop().subscribe(x => {
      this.dataSource = x.data;
    })
  }

  verMas() {
    this.service.GetMovimientosTop(2).subscribe(x => {
      this.dataSource = x.data;
    })
  }

}
