import { Component, OnInit, Input } from '@angular/core';
import { IMovimientos, TipoMovimientoFilter, EstadosFilter, TipoFilter } from 'src/app/classes/imovimientos';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {



  @Input() movi: IMovimientos;

  constructor() { }

  ngOnInit() {

  }

  get label() {
    return new TipoMovimientoFilter().getLabel(this.movi.COD_MOV, this.movi.IMPORTE);

  }


  get icon() {

    return new TipoMovimientoFilter().getIcon(this.movi.COD_MOV, this.movi.IMPORTE);

  }

  get estado() {
    return new EstadosFilter().getEstado(this.movi.ID_ESTADO);

  }
  get color() {

    return new EstadosFilter().getColor(this.movi.ID_ESTADO);

  }
  get IconColor() {
    return new TipoFilter().getColor(this.movi.IMPORTE);
  }

  get tipo() {
    return new TipoFilter().getTipo((this.movi.IMPORTE < 0) ? 2 : 1);

  }

}
