import { RequestClass } from 'src/app/services/request.class';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../../services/request.class";
export class MovimientosService {
    constructor(request) {
        this.request = request;
    }
    GetMovimientos(periodo, tipo, movimiento, estado, colaborador) {
        return this.request.ApiGet(`/movimientos/${periodo}/${tipo}/${movimiento}/${estado}/${colaborador}`).pipe(catchError(e => {
            return of({ res: false, err: "Se Produjo un error inesperado." });
        }));
    }
    GetEntityById(id = 0) {
        return this.request.ApiGet(`/movimientos/${id}`).pipe(catchError(e => {
            return of({ res: false, err: "Se Produjo un error inesperado." });
        }));
    }
    Cancelar(codMov, numMov) {
        return this.request.ApiDelete(`/movimientos/${codMov}/${numMov}`).pipe(catchError(e => {
            return of({ res: false, err: "Se Produjo un error inesperado." });
        }));
    }
    GetColaboradores() {
        return this.request.ApiGet(`/comercios/colaboradores`).pipe(catchError(e => {
            return of({ res: false, err: "Se Produjo un error inesperado." });
        }));
    }
}
MovimientosService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MovimientosService_Factory() { return new MovimientosService(i0.ɵɵinject(i1.RequestClass)); }, token: MovimientosService, providedIn: "root" });
