<div class="ContainerWallet">
    <h1>{{titulo}}</h1>
    <h4 [ngStyle]="{color: colorEstado}">{{estado}}</h4>
    <mat-card class="WalletCard descripcion">
        <mat-card-content>
            <p>
                <b>Descripción<span *ngIf="detalle.MOTIVO">/ Motivo</span>:</b> {{detalle.CONCEPTO}}
                <span class="motivo" *ngIf="detalle.MOTIVO"> {{detalle.MOTIVO}}</span>
            </p>



        </mat-card-content>
    </mat-card>
    <div class="fecha">
        <mat-label>{{fechaLabel}}</mat-label>
        <div class="w-100"></div><span>{{detalle.FECHA | date:'dd/MM/yyyy'}}</span>
    </div>
    <hr *ngIf=detalle.FECHA_RETIRO>
    <div class="fecha" *ngIf=detalle.FECHA_RETIRO>
        <mat-label>Se acredita el:</mat-label>
        <div class="w-100"></div><span>{{detalle.FECHA_RETIRO | date:'dd/MM/yyyy'}}</span>
    </div>

    <hr *ngIf="detalle.IMPORTE > 0 ">
    <div class="fecha" *ngIf="detalle.IMPORTE > 0 ">
        <mat-label>Cargos aplicados:</mat-label>
        <div class="w-100"></div><span>{{detalle.CARGOS | currency:"$":"symbol"}}</span>
    </div>

    <mat-card class="WalletCard importe">
        <mat-card-content>
            <b>Importe: </b>{{total | currency:"$":"symbol"}}
        </mat-card-content>
    </mat-card>

    <p> N° de Operación {{detalle.NUM_MOV}}</p>

    <button mat-raised-button color="primary" (click)=back()>Atrás</button>
</div>