import { Routes } from '@angular/router';
import { E404Component } from '../public/e404/e404.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from '../public/login/login.component';
import { AuthGuard } from '../services/auth.guard';
import { ValidateRecoverPasswordComponent } from '../public/validate-recover-password/validate-recover-password.component';
import { MainLayoutComponent } from '../shared/components/main-layout/main-layout.component';
import { ActivateAccountComponent } from '../public/activate-account/activate-account.component';
import { RecoverPasswordComponent } from '../public/recover-password/recover-password.component';
import { MovimientosComponent } from './movimientos/movimientos.component';
import { DetalleComponent } from './movimientos/components/detalle/detalle.component';
import { PjGuard } from '../services/pj.guard';
import { VerificacionIdentidadRepresentanteComponent } from '../public/register/cuenta-empresa/verificacion-identidad-representante/verificacion-identidad-representante.component';
import { ColaboradorGuard } from '../services/colaborador.guard';
import { RegretBajaComponent } from '../public/regret-baja/regret-baja.component';
const ɵ0 = { title: 'Inicio' }, ɵ1 = { title: 'Movimientos' }, ɵ2 = { title: 'Detalle' }, ɵ3 = () => import("./cobrar/cobrar.module.ngfactory").then(m => m.CobrarModuleNgFactory), ɵ4 = () => import("./recargas/recargas.module.ngfactory").then(m => m.RecargasModuleNgFactory), ɵ5 = () => import("./retirar/retirar.module.ngfactory").then(m => m.RetirarModuleNgFactory), ɵ6 = () => import("./ingresar/ingresar.module.ngfactory").then(m => m.IngresarModuleNgFactory), ɵ7 = () => import("./configuracion/configuracion.module.ngfactory").then(m => m.ConfiguracionModuleNgFactory), ɵ8 = { title: '' };
//Routes Declarations:
const routes = [
    // { path: '', redirectTo: '/inicio', pathMatch: 'full' },
    {
        path: '',
        canActivate: [AuthGuard, PjGuard], component: MainLayoutComponent,
        children: [
            { path: '', redirectTo: "inicio", pathMatch: "full" },
            { path: 'app/lc/:codigo', redirectTo: "cobrar/link/:codigo", pathMatch: "full" },
            { path: 'inicio', component: HomeComponent, data: ɵ0 },
            { path: 'movimientos', component: MovimientosComponent, data: ɵ1 },
            { path: 'movimientos/:id', component: DetalleComponent, data: ɵ2 },
            {
                path: 'cobrar', loadChildren: ɵ3
            },
            {
                canActivate: [ColaboradorGuard], path: 'recargas', loadChildren: ɵ4
            },
            {
                canActivate: [ColaboradorGuard], path: 'retirar', loadChildren: ɵ5
            },
            {
                canActivate: [ColaboradorGuard], path: 'ingresar', loadChildren: ɵ6
            },
            { canActivate: [ColaboradorGuard], path: 'configuracion', loadChildren: ɵ7 },
        ]
    },
    //nonLayout
    { path: 'validacion_identidad', component: VerificacionIdentidadRepresentanteComponent, data: ɵ8 },
    { path: 'login', component: LoginComponent },
    // { path: 'register', component: RegisterComponent },
    // { path: 'register/persona', component: CuentaPersonaComponent },
    // { path: 'register/empresa', component: CuentaEmpresaComponent },
    { path: 'recover_password', component: ValidateRecoverPasswordComponent },
    { path: 'recover_password/:k', component: RecoverPasswordComponent },
    { path: 'activate_account/:k', component: ActivateAccountComponent },
    { path: 'revertir_baja/:k', component: RegretBajaComponent },
    { path: '**', component: E404Component }
];
export class RoutesModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8 };
